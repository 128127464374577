const defaultExport = {
    redMain: "#d3455b",
    greenMain: "#006837",
    greenSecond: "#3f8e9a",
    greenThird: "#0F8E99",
    greenFourth: "#215F00",
    greenThirdDark: "#086169",
    greyHighlight: "#f8f8f8",
    greyMain: "#333333",
    greySecond: "#707070",
    greyThird: "#D1D9E1",
    yellowMain: "##FFFBEB",
    yellowHighlight: "#FAD433",
    difficultyEasy: "#86E500",
    difficultyMedium: "#F7DF00",
    difficultyHard: "#CC0507",
    homePanelMain: "#1A3F69",
    homePanelMinor: "#84919F",
    homePanelText: "#F7F7F7",
    hintActive: "#F4D808",
    hintInactive: "#768CA5",
    inputBorder: "#D0D0D0",
    greyLight: "#9D9A9A",
    greyBackground: "#D0D0D0",
    yellowSecond: "#fce908",
    questionIcon: "#6D9CE8D8",
    questionTag: "#52c2cc50",
    difficulty_1: "#86E500",
    difficulty_2: "#03E577",
    difficulty_3: "#00C5E8",
    difficulty_4: "#F7DF00",
    difficulty_5: "#F09E04",
    difficulty_6: "#F05D0D",
    difficulty_7: "#CC0507",
    lightThemeTextColor: "#10161A",
    lightThemeBackgroundColor: "white",
    activeButton: "#52C2CC",
    requiredColor: "#dc3545",
    pinkMain: "#FA8F8F",
};

export default defaultExport;
