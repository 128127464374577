/** @format */

import { Intent } from "@blueprintjs/core";
import * as Sentry from "@sentry/browser";
import http from "axios";
import intl from "react-intl-universal";

import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { from, of } from "rxjs";
import { ajax } from "rxjs/ajax";
import {
    catchError,
    map,
    mergeMap,
    take,
    throttleTime,
    takeUntil,
    debounceTime,
} from "rxjs/operators";

import {
    apolloClientInstance as client,
    showToast,
} from "src/app/AppSupport/Utils";
import { ActionTypes } from "../app_actions";
import {
    IEpicErrorPayload,
    IEpicSentryMessagePayload,
    IStore,
    ISetCurrentLanguage,
    ISaveRoleLanguagePref,
    IShowToastPayload,
    ICaptureAppRoleEventPayload,
    ICaptureHelpVideoEventPayload,
} from "../reducer_types";
import { TOKEN_REFRESH_WITH_GQL } from "src/app/graphql/QSimpleForTokenRefresh";
import { CAPTURE_APP_ROLE_EVENT } from "src/app/graphql/MCaptureAppRoleEvent";
import { CAPTURE_HELP_VIDEO_EVENT } from "src/app/graphql/MCaptureHelpVideoEvent";
import { Languages } from "src/Resources";

const xtx = (str: string, key?: string) => intl.get(key || str || "x") || str;

const MIN_TIME = parseInt(
    process.env.REACT_APP_DB_MESSAGE_MIN_TIME || "5000",
    10
);
const VARIABLE_TIME = parseInt(
    process.env.REACT_APP_DB_MESSAGE_VARIABLE_TIME || "5000",
    10
);

const databaseStartingMessages: [string, string, number][] = [
    [
        "The backend database should be running shortly ...",
        "epics.starting_msg_1",
        0,
    ],
    [
        "Cranking it up ...",
        "epics.starting_msg_2",
        1 + Math.floor(Math.random() * 9),
    ],
    [
        "Flipping the big red switch",
        "epics.starting_msg_3",
        1 + Math.floor(Math.random() * 9),
    ],
    [
        "Bringing the power back online",
        "epics.starting_msg_4",
        1 + Math.floor(Math.random() * 9),
    ],
    [
        "Warming up the memory",
        "epics.starting_msg_5",
        1 + Math.floor(Math.random() * 9),
    ],
];

databaseStartingMessages.sort((a, b) => a[2] - b[2]);

interface IEpicError {
    type: string;
    payload: IEpicErrorPayload;
}

interface IActionWithoutPayload {
    type: string;
}

const epicError = (
    action$: ActionsObservable<IEpicError>,
    state$: StateObservable<IStore>
) => {
    // epic receives the message in its payload and logs it to Sentry; also shows error in Toast
    return action$.pipe(
        ofType(ActionTypes.EPIC_ERROR),
        mergeMap((action) =>
            from(
                Sentry.captureException(
                    JSON.stringify(action.payload.errorData)
                )
            ).pipe(
                take(1),
                map(() => {
                    showToast(
                        action.payload.errorData.message,
                        0,
                        Intent.DANGER
                    );
                    return {
                        payload: {
                            message: "Notified Sentry in captureException",
                        },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                })
            )
        )
    );
};

interface IEpicSentryMessage {
    type: string;
    payload: IEpicSentryMessagePayload;
}

const epicSentryMessage = (
    action$: ActionsObservable<IEpicSentryMessage>,
    state$: StateObservable<IStore>
) => {
    // epic receives the message in its payload and logs it to Sentry; also shows error in Toast
    return action$.pipe(
        ofType(ActionTypes.EPIC_SENTRY_MESSAGE),
        mergeMap((action) =>
            from(
                Sentry.captureMessage(
                    JSON.stringify(action.payload.errorMessage)
                )
            ).pipe(
                take(1),
                map(() => {
                    showToast(
                        JSON.stringify(action.payload.errorMessage),
                        0,
                        Intent.DANGER
                    );
                    return {
                        payload: {
                            message: "Notified Sentry in captureMessage",
                        },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                })
            )
        )
    );
};

const refreshTokenWithGql = (
    action$: ActionsObservable<IActionWithoutPayload>,
    state$: StateObservable<IStore>
) =>
    // epic makes a gql request which will automatically refresh token if it is expired;
    // the action emitted on success will be SAVE_USER_DATA
    action$.pipe(
        ofType(ActionTypes.REFRESH_TOKEN_WITH_GQL),
        mergeMap((action) =>
            from(
                client.query({
                    query: TOKEN_REFRESH_WITH_GQL,
                })
            ).pipe(
                map(() => ({
                    // type: ActionTypes.TOKENS_UPDATED,
                    type: ActionTypes.CAPTURE_APP_ROLE_EVENT,
                    payload: {
                        variables: {
                            vAppEventCategory: "token",
                            vAppEventName: "tokens_updated",

                            vAppEventData: {},
                        },
                    },
                })),
                catchError((err) => {
                    return of({
                        type: ActionTypes.TOKEN_UPDATE_FAILURE,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IGetIpDataAction {
    type: string;
    payload: {};
}

const getIPDataEpic = (
    action$: ActionsObservable<IGetIpDataAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.GET_IP_DATA),
        mergeMap((action) =>
            // ajax.getJSON(`https://ipinfo.io/geo?token=${process.env.IPINFO_TOKEN}`).pipe(
            ajax.getJSON(`https://ipinfo.io/geo?token=19be77d0804cf4`).pipe(
                // TODO: figure out how to make the env var work
                map((response) => {
                    // @ts-ignore
                    if (response.status === null) {
                        const err = new Error("No results from IP geo request");
                        return of({
                            type: ActionTypes.EPIC_ERROR,
                            payload: { errorData: err },
                        });
                    }
                    return {
                        payload: { response },
                        type: ActionTypes.SAVE_IP_DATA,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface ISetCurrentLanguageEpic {
    type: string;
    payload: ISetCurrentLanguage;
}

const setCurrentLanguageEpic = (
    action$: ActionsObservable<ISetCurrentLanguageEpic>,
    state$: StateObservable<IStore>
) => {
    // epic receives the message in its payload and logs it to Sentry; also shows error in Toast
    return action$.pipe(
        ofType(ActionTypes.REQUEST_NEW_LANGUAGE),
        mergeMap((action) => {
            const newLanguage: string = Languages.allLanguagesList.includes(
                action.payload.language
            )
                ? action.payload.language
                : Languages.defaultLangKey;
            return from(http.get(`/locales/${newLanguage}.json`)).pipe(
                mergeMap((res) => {
                    intl.init({
                        currentLocale: newLanguage,
                        locales: {
                            [newLanguage]: res.data,
                        },
                    });
                    // const options = intl.getInitOptions();
                    // console.log(options);
                    // return {
                    //     payload: {
                    //         language: newLanguage,
                    //     },
                    //     type: ActionTypes.SET_ACTIVE_LANGUAGE,
                    // };

                    const nextActions: { type: string; payload: any }[] = [
                        {
                            payload: {
                                language: newLanguage,
                            },
                            type: ActionTypes.SET_ACTIVE_LANGUAGE,
                        },
                    ];
                    if (action.payload.updateRolePreferences) {
                        nextActions.push({
                            type: ActionTypes.SAVE_ROLE_LANGUAGE_PREFERENCE,
                            payload: {
                                roleId: state$.value.app.activeRole.id,
                                language: action.payload.language,
                            },
                        });
                    }
                    return nextActions;
                    // return {
                    //     payload: {
                    //         language: newLanguage,
                    //     },
                    //     type: ActionTypes.SET_ACTIVE_LANGUAGE,
                    // };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            );
        })
    );
};

interface ISaveRoleLanguagePrefEpic {
    type: string;
    payload: ISaveRoleLanguagePref;
}

const saveRoleLanguagePrefEpic = (
    action$: ActionsObservable<ISaveRoleLanguagePrefEpic>,
    state$: StateObservable<IStore>
) => {
    return action$.pipe(
        ofType(ActionTypes.SAVE_ROLE_LANGUAGE_PREFERENCE),
        mergeMap((action) => {
            // get existing role language preference, if any
            const targetRole = state$.value.app.roles.filter(
                (r) => r.id === action.payload.roleId
            )[0];
            const tRPrefs = targetRole.userRoleConfidentialById.rolePreferences;
            if (
                tRPrefs === null ||
                tRPrefs.language !== state$.value.app.language
            ) {
                return [
                    {
                        type: ActionTypes.SAVE_ROLE_PREFERENCES,
                        payload: {
                            activeRoleId: action.payload.roleId,
                            newPreferences: {
                                language: action.payload.language,
                            },
                        },
                    },
                    {
                        type: ActionTypes.SHOW_TOAST, // N.B. really should happen from SAVE_ROLE_PREFERENCE epic; leaving here now for testing
                        payload: {
                            toastIntent: Intent.PRIMARY,
                            toastMessage: xtx(
                                "Role language preference updated",
                                "epics.role_lang_pref_updated"
                            ),
                            toastMs: 2000,
                        },
                    },
                ];
            } else {
                return of({
                    type: ActionTypes.EPIC_COMPLETED,
                    payload: {},
                });
            }
        })
    );
};

interface IShowToastEpic {
    type: string;
    payload: IShowToastPayload;
}

const epicCompletedEpic = (
    action$: ActionsObservable<IShowToastEpic>,
    state$: StateObservable<IStore>
) => {
    return action$.pipe(
        ofType(ActionTypes.SHOW_TOAST),
        map((action) => {
            showToast(
                action.payload.toastMessage,
                action.payload.toastMs,
                action.payload.toastIntent
            );
            return {
                type: ActionTypes.EPIC_COMPLETED,
                payload: {},
            };
        })
    );
};

interface ICaptureAppRoleEventAction {
    type: string;
    payload: ICaptureAppRoleEventPayload;
}

export const captureAppRoleEventEpic = (
    action$: ActionsObservable<ICaptureAppRoleEventAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.CAPTURE_APP_ROLE_EVENT),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: CAPTURE_APP_ROLE_EVENT,
                    variables: {
                        input: {
                            ...action.payload.variables,
                            vAppEventData: {
                                ...action.payload.variables.vAppEventData,
                                asofdate: Date.now(),
                                // browserData: state$.value.app.browserData,
                            },
                            // vGeoData: state$.value.app.geoData || {},
                            vGeoData: {},
                            vRoleOwner:
                                (state$.value.app.activeRole &&
                                    state$.value.app.activeRole.id) ||
                                null,
                            vUserId: state$.value.app.dbUser.id,
                        },
                    },
                })
            ).pipe(
                map(() => {
                    return {
                        payload: {},
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface ICaptureHelpVideoEventAction {
    type: string;
    payload: ICaptureHelpVideoEventPayload;
}

export const captureHelpVideoEventEpic = (
    action$: ActionsObservable<ICaptureHelpVideoEventAction>,
    state$: StateObservable<IStore>
) => {
    if (state$.value.app.authStatus !== "signedIn") return;
    action$.pipe(
        ofType(ActionTypes.CAPTURE_HELP_VIDEO_EVENT),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: CAPTURE_HELP_VIDEO_EVENT,
                    variables: {
                        input: {
                            ...action.payload,
                        },
                    },
                })
            ).pipe(
                map(() => {
                    return {
                        payload: {},
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );
};

interface IFailToFetchEpic {
    type: string;
    payload: {};
}

const failToFetchEpic = (
    action$: ActionsObservable<IFailToFetchEpic>,
    state$: StateObservable<IStore>
) => {
    return action$.pipe(
        ofType(ActionTypes.FAIL_TO_FETCH),
        throttleTime(MIN_TIME - 500),
        takeUntil(action$.ofType(ActionTypes.FAIL_TO_FETCH_FINAL_FAILURE)),
        map((action) => {
            const currentCount = state$.value.app.failToFetchCount || 0;
            console.log(
                `in failToFetchEpic epic: currentCount is ${currentCount}`
            );
            showToast(
                xtx(
                    databaseStartingMessages[
                        currentCount % databaseStartingMessages.length
                    ][0],
                    databaseStartingMessages[
                        currentCount % databaseStartingMessages.length
                    ][1]
                ),
                MIN_TIME + VARIABLE_TIME + 7000, // adding extra so there should always be at least one message showing
                "primary"
            );
            return {
                type: ActionTypes.SET_FAIL_TO_FETCH_COUNT,
                payload: { count: currentCount + 1 },
            };
        })
    );
};

const failToFetchResetEpic = (
    action$: ActionsObservable<IFailToFetchEpic>,
    state$: StateObservable<IStore>
) => {
    return action$.pipe(
        ofType(ActionTypes.FAIL_TO_FETCH),
        debounceTime(18000), // reset counter only after 18 seconds of no more FAIL_TO_FETCH actions
        takeUntil(action$.ofType(ActionTypes.FAIL_TO_FETCH_FINAL_FAILURE)),
        map((action) => {
            console.log(`in failToFetchResetEpic epic`);
            showToast(
                xtx("Database has started", "epics.db_started"),
                12000,
                "success"
            );
            return {
                type: ActionTypes.SET_FAIL_TO_FETCH_COUNT,
                payload: { count: 0 },
            };
        })
    );
};

interface IFailToFetchFinalFailureEpic {
    type: string;
    payload: {};
}

const failToFetchFailureEpic = (
    action$: ActionsObservable<IFailToFetchFinalFailureEpic>,
    state$: StateObservable<IStore>
) => {
    return action$.pipe(
        ofType(ActionTypes.FAIL_TO_FETCH_FINAL_FAILURE),
        throttleTime(MIN_TIME + VARIABLE_TIME),
        map((action) => {
            showToast(
                xtx(
                    "Database cannot be reached, so app cannot start",
                    "epics.database_cannot_be_reached"
                ),
                0,
                "danger"
            );
            return {
                type: ActionTypes.SET_FAIL_TO_FETCH_COUNT,
                payload: { count: 0 },
            };
        })
    );
};

const defaultExports = [
    epicError,
    epicSentryMessage,
    refreshTokenWithGql,
    getIPDataEpic,
    setCurrentLanguageEpic,
    saveRoleLanguagePrefEpic,
    epicCompletedEpic,
    captureAppRoleEventEpic,
    failToFetchEpic,
    failToFetchFailureEpic,
    failToFetchResetEpic,
];

export default defaultExports;
