/** @format */

import * as textLanguage from "./textLanguage";
import * as tag from "./tag";

// requires
// ${textLanguage.textLanguageFields}
export const subjectAreaBasicFields = `
fragment subjectAreaBasicFields on SubjectArea {
    nodeId
    id
    areaName
    textLanguage: textLanguageByLanguageId {
        ...textLanguageFields
    }
}
`;

export interface ISubjectArea {
    nodeId: string;
    id: number;
    areaName: string;
    textLanguage: textLanguage.ITextLanguage;
}

// requires
// ${textLanguage.textLanguageFields}
// ${tag.tagFields}
export const subjectAreaFullFields = `
fragment subjectAreaBasicFields on SubjectArea {
    ...subjectAreaBasicFields
    tags: tagsBySubjectAreaId {
        nodes {
            ...tagFields
        }
    }
}
`;

export interface ISubjectAreaFull extends ISubjectArea {
    tagsBySubjectAreaId: {
        nodes: tag.ITag[];
    };
}
