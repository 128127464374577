/** @format */

// from https://github.com/richardzcode/Journal-AWS-Amplify-Tutorial/blob/18107609685467514f0707b7c9830eef7c386eae/step-08/journal/src/store/AmplifyBridge.js
import { Auth, Hub, Logger } from "aws-amplify";

// import { switchUser, updateProfile, deleteProfile } from "./actions";
import { Actions } from "../../redux/app_actions";
import { prepareUserData } from "src/app/AppSupport/Utils";

const logger = new Logger("AmplifyBridge");

export default class AmplifyBridge {
    constructor(store) {
        // console.log("constructing AmplifyBridge");
        this.store = store;

        Hub.listen("auth", (data) => {
            const { payload } = data;
            // console.log(`got data in Hub: ${JSON.stringify(payload)}`);
            this.onAuthEvent(payload);
            if (payload.data && payload.data.username) {
                logger.info(
                    `New auth event: "${payload.data.username}" has "${payload.event}"; `
                );
            } else {
                logger.info(`A new auth event has happened: ${payload.event}`);
            }
        });
        this.onAuthEvent({});

        // this.onHubCapsule = this.onHubCapsule.bind(this);
        // const listener = (data) => {
        //     console.log(data.payload.event);

        //     switch (data.payload.event) {
        //         case "signIn":
        //             logger.error("user signed in"); //[ERROR] My-Logger - user signed in
        //             break;
        //         case "signUp":
        //             logger.error("user signed up");
        //             break;
        //         case "signOut":
        //             logger.error("user signed out");
        //             break;
        //         case "signIn_failure":
        //             logger.error("user sign in failed");
        //             break;
        //         case "configured":
        //             logger.error("the Auth module is configured");
        //             break;
        //         default:
        //             logger.info("default");
        //     }
        //     this.checkUser(); // triggering each time
        // };
        // Hub.listen("auth", listener); // Add this component as a listener of auth events.
        // original working part
        // Hub.listen("auth", this, "AmplifyBridge");
        // this.checkUser(); // first check / initialisation
    }

    onAuthEvent(payload) {
        // console.log(
        //     `received auth event payload: "${JSON.stringify(payload)}"`
        // );
        this.checkUser(); // triggered every time user sign in / out
    }

    checkUser() {
        Auth.currentAuthenticatedUser()
            .then((user) => this.checkUserSuccess(user))
            .catch((err) => this.checkUserError(err));
    }

    loadUserInfo(user) {
        Auth.currentUserInfo()
            .then((info) => this.loadUserInfoSuccess(user, info))
            .catch((err) => this.loadUserInfoUserError(user, err));
    }

    loadProfile(user) {
        Auth.userAttributes(user)
            .then((data) => this.loadProfileSuccess(data))
            .catch((err) => this.loadProfileError(err));
    }

    checkUserSuccess(user) {
        logger.info("check user success", user);
        // console.log(
        //     `in AmplifyBridge checkUserSuccess, got user of ${JSON.stringify(
        //         user
        //     )}`
        // );
        this.store.dispatch(
            Actions.saveAuthProps({
                authProps: {
                    authState: "signedIn",
                    authData: prepareUserData(user),
                },
            })
        );
        // this.store.dispatch(Actions.requestNewLanguage({ language: "en" }));
        this.store.dispatch(Actions.saveAuthStatus({ authStatus: "signedIn" }));
        // this.loadUserInfo(user); // Defer store.dispatch to loadUserInfo
        // this.loadProfile(user);
    }

    checkUserError(err) {
        logger.info("check user error", err);
        this.store.dispatch(Actions.saveAuthStatus({ authStatus: "signIn" }));
        // this.store.dispatch(switchUser(null));
        // this.store.dispatch(deleteProfile());
    }

    loadUserInfoSuccess(user, info) {
        logger.info("load user info success", user, info);
        // console.log(
        //     `in AmplifyBridge loadUserInfoSuccess, got user of ${JSON.stringify(
        //         user
        //     )}`
        // );
        Object.assign(user, info);
        Actions.saveAuthProps({
            authProps: {
                authState: "signedIn",
                authData: prepareUserData(user),
            },
        });
        Actions.saveAuthStatus({ authStatus: "signedIn" });
        // this.store.dispatch(switchUser(user));
    }

    loadUserInfoError(user, err) {
        logger.info("load user info error", err);
        // this.store.dispatch(switchUser(user)); // Still dispatchs user object
    }

    loadProfileSuccess(data) {
        logger.info("load profile success", data);
        // const profile = this.translateAttributes(data);
        // console.log(profile);
        // this.store.dispatch(updateProfile(profile));
    }

    loadProfileError(err) {
        logger.info("load profile error", err);
        // this.store.dispatch(deleteProfile());
    }

    // Auth.userAttributes returns an array of attributes.
    // We map it to an object for easy use.
    translateAttributes(data) {
        const attributes = {};
        data.filter((attr) =>
            ["given_name", "family_name"].includes(attr.Name)
        ).forEach((attr) => (attributes[attr.Name] = attr.Value));
        return attributes;
    }
}
