/** @format */

import gql from "graphql-tag";
import {
    userRole,
    specificSubject,
    subjectArea,
    classGrouping,
    textLanguage,
    curriculumElement,
    roleSetupPreset,
} from "./types_and_fragments";
import { ICtUserData } from "./QUserInfo";

export const UPDATE_USER_AND_ROLE_INFO = gql`
    mutation upduser($input: UpdateUserOrRoleInfoInput!, $roleId: Int) {
        updateUserOrRoleInfo(input: $input) {
            ctUser {
                nodeId
                id
                cognitoSub
                mobileAppRegistered
                ctUserRoles: ctUserRolesByCtUserId(condition: { id: $roleId }) {
                    nodes {
                        ...privateRoleFields
                    }
                }
                userConfidentialById {
                    firstName
                    lastName
                    authorised
                    accessIds
                    mobile
                    pgRole
                    userPreferences
                    additionalInfo
                    email
                }
            }
        }
    }
    ${userRole.privateRoleFields}
    ${specificSubject.specificSubjectFullFields}
    ${subjectArea.subjectAreaBasicFields}
    ${classGrouping.classGroupingFields}
    ${textLanguage.textLanguageFields}
    ${curriculumElement.ceFullFields}
    ${roleSetupPreset.roleSetupPresetBasicFields}
`;

export interface IResult {
    updateUserOrRoleInfo: {
        ctUser: ICtUserData;
    };
}

export interface IUpdateUserAndRoleInfo {
    input: {
        vUserId?: number;
        vRoleId?: number;
        vFirstName?: string;
        vLastName?: string;
        vUserPreferences?: any; // jsonb
        vAdditionalInfoUser?: any; // jsonb
        vPublicName?: string;
        vRoleStartDate?: string; // bigint
        vRoleEndDate?: string; // bigint
        vRolePreferences?: any; // jsonb
        vAdditionalInfoRole?: any; // jsonb
        vRoleDescription?: string;
    };
}

// OLD
// export const UPDATE_ROLE_PREFERENCES = gql`
//     mutation setrolepreferences($input: UpdateUserRoleConfidentialByIdInput!) {
//         updateUserRoleConfidentialById(input: $input) {
//             clientMutationId
//         }
//     }
// `;
// export interface ISetRolePreferencesInput {
//     input: {
//         id: number;
//         userRoleConfidentialPatch: {
//             rolePreferences: {
//                 [key: string]: string | number | boolean;
//             };
//         };
//     };
// }
