import ReduxConstants from "../ReduxConstants";
// import { StringTools } from "../app/AppSupport";
// import { PhoneNumberTools } from "src/app/AppSupport";

// const fixPhoneNumber = (number, desiredValue) => {
//     const numberObj = PhoneNumberTools.parseNumber(number);
//     return numberObj[desiredValue]
// };

const { Account } = ReduxConstants;

const defaultExport = (
    state = {
        registrationDetails: {
            userType: "student",
            firstName: "",
            lastName: "",
            email: "",
            countryCode: "",
            mobile: "",
            fullMobileNumber: "",
            invitedBy: "",
            recaptchaScore: undefined,
        },
        loginDetails: {
            emailAddress: "",
            accessCode: "",
        },
    },
    action
) => {
    switch (action.type) {
        case Account.SetRegistrationDetails:
            state = {
                ...state,
                registrationDetails: {
                    ...state.registrationDetails,
                    ...action.payload,
                },
            };
            // state = {
            //     ...state,
            //     registrationDetails: {
            //         ...state.registrationDetails,
            //     },
            // };
            break;
        case Account.SetLoginDetails:
            state = {
                ...state,
                loginDetails: {
                    ...state.loginDetails,
                    ...action.payload,
                },
            };
            break;
        default:
            break;
    }

    return { ...state };
};

export default defaultExport;
