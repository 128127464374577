import {
    Intent,
    IToastProps,
    Position,
    Toaster,
    IconName,
    IActionProps,
} from "@blueprintjs/core";
import * as Sentry from "@sentry/browser";
import { IContentCollection } from "src/app/graphql/types_and_fragments/contentCollection";

// from https://stackoverflow.com/a/196991/4416993
export const toTitleCase = (str: any) => {
    return str.replace(/\w\S*/g, (txt: any) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export const computeSeconds = (hms: string): number => {
    const a = hms.split(":");
    const alen = a.length;
    const seconds =
        (+a[alen - 3] || 0) * 60 * 60 + (+a[alen - 2] || 0) * 60 + +a[alen - 1];
    return seconds;
};

export const computeMinSecText = (s: number): string => {
    const minutes = Math.floor(s / 60);
    const seconds = Math.floor(s - minutes * 60);
    // from https://stackoverflow.com/questions/12230343/how-can-i-display-time-with-leading-zeros
    // N.B. padStart wasn't shown as available
    const minLength = minutes > 100 ? 3 : 2;
    return `${("0" + minutes).slice(-minLength)}:${("0" + seconds).slice(-2)}`;
};

export const onError = (
    message: string | null,
    e,
    additionalData?: { [key: string]: string }
) => {
    if (additionalData) {
        Sentry.withScope((scope) => {
            Object.keys(additionalData).forEach((k) => {
                scope.setTag(k, additionalData[k]);
            });
            Sentry.captureException(e);
        });
    } else {
        Sentry.captureException(e);
    }
    if (message) {
        showToast(
            `${message}: ${
                (e !== null && e !== undefined && e.message) ||
                "no further error data available"
            }`,
            0,
            Intent.DANGER
        );
    }
};

// export const getMarkdownInnerHtml = (markdownText: string) => {
//     let mdInnerHtml = "";
//     if (markdownText) {
//         try {
//             mdInnerHtml = md.render(markdownText);
//         } catch (e) {
//             mdInnerHtml = "Error with markdown";
//         }
//     }
//     return mdInnerHtml;
// };

export const gradeLevels = [
    { key: "<G1", text: "Below Grade 1" }, // TODO - for some reason this choice doesn't work
    { key: "G1_2", text: "Grades 1-2" },
    { key: "G3_4", text: "Grades 3-4" },
    { key: "G5_6", text: "Grades 5-6" },
    { key: "G7_8", text: "Grades 7-8" },
    { key: "G9_10", text: "Grades 9-10" },
    { key: "G11_12", text: "Grades 11-12" },
    { key: "Univ", text: "University" },
    { key: "Grad", text: "Graduate school" },
];

export const AppToaster = Toaster.create({
    className: "messages-toaster",
    position: Position.BOTTOM_LEFT,
});

export const showToast = (
    message: React.ReactNode,
    timeout: number,
    intent: Intent,
    action?: IActionProps,
    icon?: IconName
) => {
    const toastVals: IToastProps = {
        intent,
        message,
        timeout,
    };
    if (action) {
        toastVals.action = action;
    }
    if (icon) {
        toastVals.icon = icon;
    }
    AppToaster.show(toastVals);
};

export const clearToasts = () => {
    AppToaster.clear();
};

// https://stackoverflow.com/a/7254108/4416993
function pad(a, b) {
    return (1e15 + a + "").slice(-b);
}

export const ccSortVal = (c: IContentCollection) => {
    if (c.additionalData && c.additionalData.ce_dist) {
        return `${pad(c.additionalData.ce_dist[0].display_order * 10, 5)}-${
            c.collectionTitle
        }`;
    } else {
        return `99999-${c.collectionTitle}`;
    }
};
