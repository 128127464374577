/** @format */

// import { IQResultData } from "./questionResult"; --> becomes circular
import { ISubjectArea } from "./subjectArea";

interface IQResultData {
    asofdate: string;
    score: number;
}

interface IQuestionTextOnly {
    questionText: string;
}

export interface IQuestionMinimal extends IQuestionTextOnly {
    nodeId: string;
    id: number;
}

export interface IQuestionCore extends IQuestionMinimal {
    createdById: number;
    alwaysInAnswerForFact?: string;
    imageName?: string;
    imageNameIdentityId?: string;
    answerImageName?: string;
    answerImageNameIdentityId?: string;
    answerAreaSize?: string;
    answer: string;
    helpText?: string;
    category: string;
    canReverse: boolean;
    doNotShow: boolean;
    followsOther?: number;
    overrideFieldList?: number[];
    additionalInfo?: any; // jsonb
    unfinished: boolean;
    fullWriteList: string[]; // uuid[];
    questionTextIsMarkdown: boolean;
    answerIsMarkdown: boolean;
    quizQuestionsByQuestionId?: {
        totalCount: number;
    };
    preQuestionStoryByPreQuestionStoryId?: {
        id: number;
        nodeId: string;
        story: string;
    };
    tags?: {
        nodes: {
            id: number;
            nodeId: string;
            tag: string;
        }[];
    };
}

export interface IQuestionModified extends IQuestionCore {
    subjectAreaId: number;
}

export interface IQuestion extends IQuestionCore {
    questionResultsByQuestionId?: {
        nodes: IQResultData[];
    };
    subjectAreaBySubjectAreaId: ISubjectArea;
}

export interface IQuestionWithCE extends IQuestion {
    ccdId?: number; // these two fields may be added by code in EditCollection
    contentOrder?: number;
    questionResultId?: number;
    curriculumElementInfo?: {
        curriculum_element_text: string;
        chapter: string;
        section: string;
        curriculum_element_id: number;
        difficulty: number;
        specific_subject_id: number;
    };
}

export interface IDragQuestionItem {
    id: number;
    indexBeforeDrag: number;
    q: IQuestionWithCE;
    type: string;
    index?: number;
}

export const coreQuestionFields = `
fragment coreQuestionFields on Question {
    nodeId
    id
    createdById
    questionText
    alwaysInAnswerForFact
    imageName
    imageNameIdentityId
    answerImageName
    answerImageNameIdentityId
    answerAreaSize
    answer
    helpText
    category
    canReverse
    doNotShow
    followsOther
    overrideFieldList
    additionalInfo
    unfinished
    fullWriteList
    questionTextIsMarkdown
    answerIsMarkdown
    quizQuestionsByQuestionId {
        totalCount
    }
    preQuestionStoryByPreQuestionStoryId {
        id
        nodeId
        story
    }
    tags: tagsByQuestionTagQuestionIdAndTagId(orderBy: TAG_ASC) {
        nodes {
            id
            nodeId
            tag
        }
    }
}
`;

export const questionFields = `
fragment questionFields on Question {
    ...coreQuestionFields
    questionResultsByQuestionId {
        nodes {
            nodeId
            id
            score
            asofdate
            }
    }
    subjectAreaBySubjectAreaId {
        nodeId
        id
        areaName
    }
}
`;

export const questionFieldsWithCE = `
fragment questionFieldsWithCE on Question {
    ...questionFields
    curriculumElementInfo(vSpecificSubjectId: $vSpecificSubjectId)
}
`;
