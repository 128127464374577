/** @format */

import gql from "graphql-tag";

import {
    userRole,
    specificSubject,
    classGrouping,
    subjectArea,
    textLanguage,
    curriculumElement,
    roleSetupPreset,
} from "./types_and_fragments";

export const CREATE_NEW_ROLE = gql(`
mutation cnr($input: CreateNewRoleProtectedInput!) {
    createNewRoleProtected(input: $input) {
        role: ctUserRole {
            ...privateRoleFields
        }
    }
}
${userRole.privateRoleFields}
${specificSubject.specificSubjectFullFields}
${classGrouping.classGroupingFields}
${subjectArea.subjectAreaBasicFields}
${textLanguage.textLanguageFields}
${curriculumElement.ceFullFields}
${roleSetupPreset.roleSetupPresetBasicFields}
`);

export interface ICreateNewRoleInput {
    input: {
        vUserId: number;
        vPublicName: string;
        vClassGroupingId?: number;
        vSpecificSubjectId?: number;
        vRoleStartDate: number; // bigint
        vRoleEndDate: number; // bigint
        vRoleSpecificEmail?: string;
        vRoleDescription?: string;
        vUserRole: string;
        vRoleLanguage: string;
        vSubjectAreaId?: number;
        vAreaName?: string;
        vLanguageId?: number;
        vSpecificSubjectName?: string;
        vGradeLevel?: string;
        vClassName: string;
        vClassShortDesc?: string;
        vClassLongDesc?: string;
        vRoleSetupPresetId: number;
        vShareResultsWithParents: boolean;
        vShareResultsWithTutor: boolean;
        vShareResultsWithTeachers: boolean;
        vInvitations: any; // JSON
    };
}

export interface ICreateNewRoleResult {
    createNewRoleProtected: {
        role: userRole.IUserRole;
    };
}
