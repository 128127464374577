/** @format */

import gql from "graphql-tag";

import {
    classGrouping,
    curriculumElement,
    other,
    specificSubject,
    subjectArea,
    textLanguage,
    userRole,
    roleSetupPreset,
    invitation,
} from "./types_and_fragments";

export const GET_USER_SUBJ_AND_DB_INFO = gql`
    query userRoles($cognitoSub: UUID!) {
        gradeLevels: allQEnums(
            orderBy: ENUMSORTORDER_ASC
            condition: { typname: "grade_level" }
        ) {
            nodes {
                enumlabel
            }
        }
        myGroupsAndCognitoSubList
        ctUser: ctUserByCognitoSub(cognitoSub: $cognitoSub) {
            nodeId
            id
            cognitoSub
            mobileAppRegistered
            ctUserRoles: ctUserRolesByCtUserId(condition: { removed: false }) {
                nodes {
                    ...privateRoleFields
                }
            }
            userConfidentialById {
                firstName
                lastName
                authorised
                accessIds
                mobile
                pgRole
                userPreferences
                additionalInfo
                email
            }
        }
        invitations: getInvitationList {
            nodes {
                ...invitationWithClassFields
            }
        }
    }
    ${invitation.invitationBasicFields}
    ${invitation.invitationWithClassFields}
    ${userRole.privateRoleFields}
    ${specificSubject.specificSubjectFullFields}
    ${subjectArea.subjectAreaBasicFields}
    ${classGrouping.classGroupingFields}
    ${textLanguage.textLanguageFields}
    ${curriculumElement.ceFullFields}
    ${roleSetupPreset.roleSetupPresetFullFields}
    ${roleSetupPreset.roleSetupPresetBasicFields}
`;

export const GET_UPDATED_USER_INFO = gql`
    query updatedUserInfo($cognitoSub: UUID!) {
        ctUser: ctUserByCognitoSub(cognitoSub: $cognitoSub) {
            nodeId
            id
            cognitoSub
            mobileAppRegistered
            ctUserRoles: ctUserRolesByCtUserId(condition: { removed: false }) {
                nodes {
                    ...privateRoleFields
                }
            }
            userConfidentialById {
                firstName
                lastName
                authorised
                accessIds
                mobile
                pgRole
                userPreferences
                additionalInfo
                email
            }
        }
        invitations: getInvitationList {
            nodes {
                ...invitationWithClassFields
            }
        }
    }
    ${invitation.invitationBasicFields}
    ${invitation.invitationWithClassFields}
    ${userRole.privateRoleFields}
    ${specificSubject.specificSubjectFullFields}
    ${subjectArea.subjectAreaBasicFields}
    ${classGrouping.classGroupingFields}
    ${textLanguage.textLanguageFields}
    ${curriculumElement.ceFullFields}
    ${roleSetupPreset.roleSetupPresetFullFields}
    ${roleSetupPreset.roleSetupPresetBasicFields}
`;

export interface IUserInfoResult {
    myGroupsAndCognitoSubList: string[];
    gradeLevels: {
        nodes: other.IEnumResult[];
    };
    ctUser: ICtUserData;
    invitations: {
        nodes: invitation.IInvitationWithClass[];
    };
}

export interface IVariables {
    cognitoSub: string;
}

export interface IUpdatedUserInfo {
    myGroupsAndCognitoSubList: string[];
    ctUser: ICtUserData;
    invitations: {
        nodes: invitation.IInvitationWithClass[];
    };
}

export interface ICtUserData {
    nodeId: string;
    id: number;
    cognitoSub: string;
    mobileAppRegistered: boolean;
    ctUserRoles: {
        nodes: userRole.IUserRole[];
    };
    userConfidentialById: {
        firstName: string;
        lastName: string;
        authorised: boolean;
        accessIds: string[];
        mobile: string;
        pgRole: string;
        userPreferences: {
            [key: string]: any;
        };
        additionalInfo: {
            [key: string]: any;
        };
        email: string;
    };
}
