/** @format */

import gql from "graphql-tag";

export const TOKEN_REFRESH_WITH_GQL = gql`
    query quickNEasy {
        userConfidentials {
            totalCount
        }
    }
`;
