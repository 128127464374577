/** @format */

export const roleSetupPresetBasicFields = `
fragment roleSetupPresetBasicFields on RoleSetupPreset {
    nodeId
    id
    presetSequenceKey
    presetSequenceName
    presetSequenceDescription
    limitToUserRoleTypes
    limitByUserRoleTypes
}
`;

export interface IRoleSetupPresetBasic {
    nodeId: string;
    id: number;
    presetSequenceKey: string;
    presetSequenceName: string;
    presetSequenceDescription: string;
    limitToUserRoleTypes: string[];
    limitByUserRoleTypes: string[];
}

export const roleSetupPresetFullFields = `
fragment roleSetupPresetFullFields on RoleSetupPreset {
    nodeId
    id
    presetSequenceKey
    presetSequenceName
    presetSequenceDescription
    limitToUserRoleTypes
    limitByUserRoleTypes
    createStudentGroup
    createTeacherGroup
    createAdminGroup
    createParentGroup
    newSpecificSubjectIsPublic
    collectionsArePublic
    questionsArePublic
    newTagsArePublic
    adminsRwCollections
    teachersRwCollections
    studentsRwCollections
    parentsRwCollections
    adminsRwQuestions
    teachersRwQuestions
    studentsRwQuestions
    parentsRwQuestions
    shareResultsWithParents
    shareResultsWithTutor
    shareResultsWithTeachers
}
`;

export interface IRoleSetupPresetFull extends IRoleSetupPresetBasic {
    createStudentGroup: boolean;
    createTeacherGroup: boolean;
    createAdminGroup: boolean;
    createParentGroup: boolean;
    newSpecificSubjectIsPublic: boolean;
    collectionsArePublic: boolean;
    questionsArePublic: boolean;
    newTagsArePublic: boolean;
    adminsRwCollections: boolean;
    teachersRwCollections: boolean;
    studentsRwCollections: boolean;
    parentsRwCollections: boolean;
    adminsRwQuestions: boolean;
    teachersRwQuestions: boolean;
    studentsRwQuestions: boolean;
    parentsRwQuestions: boolean;
    shareResultsWithParents: boolean;
    shareResultsWithTutor: boolean;
    shareResultsWithTeachers: boolean;
}
