/** @format */

import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { composeWithDevTools } from "redux-devtools-extension";

import { appRootReducer, rootEpic } from "../../redux";
import { authReducer } from "../../redux_auth";
import account from "../../Reducers/AccountReducer";
import general from "../../Reducers/GeneralReducer";

// import { ReactotronMiddleware } from "../../Middleware";

const rootReducer = combineReducers({
    app: appRootReducer,
    auth: authReducer,
    account,
    general,
});
let composeEnhancers = compose;
if (!process.env.REACT_APP_IS_PROD) {
    try {
        composeEnhancers = composeWithDevTools({
            // Specify name here, actionsBlacklist, actionsCreators and other options if needed
            trace: true,
            traceLimit: 25,
        });
        // @ts-ignore
        // composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        //     maxAge: 500,
        //     trace: true,
        // });
    } catch {
        // do nothing
    }
}
const epicMiddleware = createEpicMiddleware();

export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(epicMiddleware)) // ReactotronMiddleware
);

// @ts-ignore
epicMiddleware.run(rootEpic);

// import { applyMiddleware, combineReducers, compose, createStore } from "redux";
// import { createEpicMiddleware } from "redux-observable";

// import { authReducer } from "@mpl/amplify-auth";
// import { appRootReducer, rootEpic } from "../../redux";
// import { IStoreAuthInitial } from "../reducer_types";

// import authConfig from "../../auth_config/authConfig";

// const rootReducer = combineReducers({app: appRootReducer, auth: authReducer});
// // @ts-ignore
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const epicMiddleware = createEpicMiddleware();

// export const authInitialState: IStoreAuthInitial = {
//     authConfig,
//     authProps: {
//         authState: "",
//     },
//     changedState: "",
//     redirectAfterLoginTo: "",
//     signedInUsername: "",
// };

// const initialState = {
//     app: {},
//     auth: authInitialState,
// };

// // export const store = createStore(
// //     rootReducer,
// //     initialState,
// //     // @ts-ignore
// //     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
// // );

// // import { compose } from 'redux'; // and your other imports from before

// export const store = createStore(
//     rootReducer,
//     initialState,
//     composeEnhancers(
//             applyMiddleware(epicMiddleware),
//     ),
// );

// // @ts-ignore
// epicMiddleware.run(rootEpic);
