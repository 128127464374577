import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, map, mergeMap, debounceTime } from "rxjs/operators";

import { ActionTypes } from "../app_actions";
import { IStore, IRequestFilteredQuestions } from "../reducer_types";
import { apolloClientInstance as client } from "src/app/AppSupport/Utils";
import {
    FILTER_QUESTIONS_BY_SUBJECT_AREA,
    FILTER_QUESTIONS_BY_SPECIFIC_SUBJECT,
} from "src/app/graphql/QGetFilteredQuestions";

interface IRequestFilteredQuestionsAction {
    type: string;
    payload: IRequestFilteredQuestions;
}

const requestFilteredQuestionsEpic = (
    action$: ActionsObservable<IRequestFilteredQuestionsAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.REQUEST_FILTERED_QUESTIONS),
        debounceTime(500),
        mergeMap((action) => {
            const query = action.payload.vSubjectAreaId
                ? FILTER_QUESTIONS_BY_SUBJECT_AREA
                : FILTER_QUESTIONS_BY_SPECIFIC_SUBJECT;
            const variables = action.payload;
            return from(
                client.query({
                    query,
                    variables,
                })
            ).pipe(
                map((result) => {
                    return {
                        payload: {
                            filteredQuestionsData:
                                result.data.filteredQuestionsData,
                        },
                        type: ActionTypes.SAVE_FILTERED_QUESTIONS,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            );
        })
    );

// N.B. the combineEpics function needs a list of epics
const defaultExports = [requestFilteredQuestionsEpic];
export default defaultExports;
