/** @format */

import gql from "graphql-tag";

export const GRADE_QUIZ = gql(`
mutation m1(
    $vActiveRoleId: Int!,
    $vAsofdate: BigInt!,
    $vQuizId: Int!,
    $vScoresData: JSON!,
    $vGradedBy: Int,
    $clientMutationId: String
) {
    gradeQuiz(input: {
        vActiveRoleId: $vActiveRoleId
        vAsofdate: $vAsofdate
        vQuizId: $vQuizId
        vScoresData: $vScoresData
        vGradedBy: $vGradedBy
        clientMutationId: $clientMutationId
    }) {
        clientMutationId
    }
}
`);

export interface IDataNumGradesAdded {
    clientMutationId: string;
}

export interface IGradeQuizVars {
    vActiveRoleId: number;
    vAsofdate: number;
    vQuizId: number;
    vScoresData: any; // json b
    vGradedBy?: number;
    clientMutationId?: string;
}
