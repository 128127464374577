/** @format */

import { IRoleSetupPresetBasic } from "./roleSetupPreset";

export interface IClassGrouping {
    className: string;
    classShortDesc: string;
    classLongDesc: string;
    ss: {
        specificSubjectName: string;
    };
    clientName: string;
    fullWriteList: string[];
    roleSetupPreset: IRoleSetupPresetBasic;

    // people: { -- TODO - replace with another method now that things have changed or create computed field
    //     nodes: [
    //         {
    //             publicName;
    //         }
    //     ];
    // };
}

// requires
// ${roleSetupPreset.roleSetupPresetBasicFields}
export const classGroupingFields = `
fragment classGroupingFields on ClassGrouping {
    className
    classShortDesc
    classLongDesc
    ss: specificSubjectBySpecificSubjectId {
        specificSubjectName
    }
    clientName
    fullWriteList
    roleSetupPreset: roleSetupPresetByRoleSetupPresetId {
        ...roleSetupPresetBasicFields
    }
}
`;
