import ReduxConstants from "../ReduxConstants";
import { Enums } from "../Resources";
import { InitialStates } from "../app/Constants";

const { General } = ReduxConstants;

const defaultExport = (dispatch) => {
    return {
        setCurrentLanguage: (language) => {
            dispatch({
                type: General.SetCurrentLanguage,
                payload: { language },
            });
        },
        showDialog: (
            title,
            ContentComponent,
            buttonsType = Enums.DialogButtonTypes.CloseOnly,
            onCancel,
            onConfirm
        ) => {
            dispatch({
                type: General.SetDialogStatus,
                payload: {
                    isOpen: true,
                    title,
                    ContentComponent,
                    buttonsType,
                    onCancel: onCancel ? onCancel : () => {},
                    onConfirm: onConfirm ? onConfirm : () => {},
                },
            });
        },
        hideDialog: () => {
            dispatch({
                type: General.SetDialogStatus,
                payload: InitialStates.getDefaultDialogState(),
            });
        },
    };
};

export default defaultExport;
