/** @format */

import { Intent } from "@blueprintjs/core";
import intl from "react-intl-universal";
import produce from "immer";

import { userRole } from "../app/graphql/types_and_fragments";
import * as fromActions from "./app_actions";
import * as rt from "./reducer_types";
import { showToast } from "src/app/AppSupport/Utils";

const xtx = (str: string, key?: string) => intl.get(key || str || "x") || str;

// @ts-ignore
export const appReducer = (
    // @ts-ignore
    state: rt.IStoreApp = {},
    action: fromActions.ActionsType
) => {
    switch (action.type) {
        case fromActions.ActionTypes.SAVE_USER_DATA: {
            const payload: rt.ISaveUserPayload = action.payload;
            const { user } = payload;
            if (state.user && user.cognitoSub !== state.user.cognitoSub) {
                // new user has logged in; reset roles and activeRole
                return {
                    ...state,
                    user,
                    activeRole: undefined,
                    roles: [],
                };
            } else {
                return { ...state, user };
            }
        }

        case fromActions.ActionTypes.SAVE_GROUP_UUID_LIST: {
            const payload: rt.ISaveGroupUuidList = action.payload;
            const { groupUuids } = payload;

            return { ...state, groupUuids };
        }

        case fromActions.ActionTypes.SAVE_INVITATIONS: {
            const payload: rt.ISaveInvitations = action.payload;
            const { invitations } = payload;

            return { ...state, invitations };
        }

        case fromActions.ActionTypes.SAVE_GRADE_LEVEL_ENUMS: {
            const payload: rt.ISaveGradeLevelEnums = action.payload;
            const { gradeLevelEnums } = payload;

            return { ...state, gradeLevelEnums };
        }

        case fromActions.ActionTypes.SAVE_RELEVANT_TAG_LIST: {
            const payload: rt.ISaveRelevantTagsPayload = action.payload;
            const { relevantTags } = payload;

            return { ...state, relevantTags };
        }

        case fromActions.ActionTypes.SAVE_SPECIFIC_SUBJECT_INFO: {
            const payload: rt.ISaveSpecificSubjectInfo = action.payload;
            const { specificSubjectInfo } = payload;

            const { specificSubjects } = state;
            const newSs = {
                ...specificSubjects,
                [specificSubjectInfo.id]: specificSubjectInfo,
            };

            return { ...state, specificSubjects: newSs };
        }

        case fromActions.ActionTypes.SAVE_ROLES_DATA: {
            return produce(state, (draft) => {
                draft.dbUser = action.payload.dbUser;
                const newRolesIds = action.payload.roles.map((r) => r.id);
                // TODO: consider the logic here - why are we keeping existing roles that are not being provided any longer?
                const existingRolesToKeep = (draft.roles || []).filter(
                    (r) => newRolesIds.indexOf(r.id) === -1
                );
                draft.roles = existingRolesToKeep.concat(action.payload.roles);
                draft.roles.sort((a, b) =>
                    a.userRoleConfidentialById.roleDescription >
                    b.userRoleConfidentialById.roleDescription
                        ? 1
                        : a.userRoleConfidentialById.roleDescription ===
                          b.userRoleConfidentialById.roleDescription
                        ? 0
                        : -1
                );
                if (!draft.questionListByRole) {
                    draft.questionListByRole = {};
                }
                draft.roles.forEach((r) => {
                    draft.questionListByRole![r.id.toString()] =
                        r.userRoleConfidentialById.rolePreferences
                            .questionList || [];
                });
            });
        }

        case fromActions.ActionTypes.SAVE_ONE_ROLE: {
            return produce(state, (draft) => {
                const newRole = action.payload.role;
                const existingRolesToKeep = (draft.roles || []).filter(
                    (r) => r.id !== newRole.id
                );
                draft.roles = existingRolesToKeep.concat([newRole]);
                draft.roles.sort((a, b) =>
                    a.userRoleConfidentialById.roleDescription >
                    b.userRoleConfidentialById.roleDescription
                        ? 1
                        : a.userRoleConfidentialById.roleDescription ===
                          b.userRoleConfidentialById.roleDescription
                        ? 0
                        : -1
                );
                if (!draft.questionListByRole) {
                    draft.questionListByRole = {};
                }
                draft.questionListByRole![newRole.id.toString()] =
                    newRole.userRoleConfidentialById.rolePreferences
                        .questionList || [];
            });
        }

        case fromActions.ActionTypes.CHOOSE_ROLE: {
            // TODO - also make an Epic save this selection through Graph QL
            const payload: rt.IChooseRole = action.payload;
            const { roleId } = payload;
            const { currentStudent, roles } = state;
            const activeRoleList = roles.filter((role) => role.id === roleId);
            const activeRole = activeRoleList[0];

            const rs = activeRole.myStudents.nodes || [];
            const rsIds = rs.map((student) => student.id);
            if (
                currentStudent &&
                rsIds.indexOf(currentStudent.studentId) === -1
            ) {
                return {
                    ...state,
                    activeRole,
                    currentStudent: null,
                    lastRoleId: roleId,
                };
            } else {
                return { ...state, activeRole, lastRoleId: roleId };
            }
        }

        case fromActions.ActionTypes.SAVE_IDENTITY_ID: {
            const payload: rt.ISaveIdentityIdPayload = action.payload;
            const { identityId } = payload;
            return {
                ...state,
                currentIdentityId: identityId,
            };
        }

        case fromActions.ActionTypes.CHOOSE_STUDENT: {
            const payload: rt.IChooseStudent = action.payload;
            const { studentId } = payload;
            const activeRole: userRole.IUserRole = state.activeRole;
            const rs = activeRole.myStudents.nodes || [];
            const rsIds = rs.map((student) => student.id);
            if (rsIds.indexOf(studentId) !== -1) {
                const currentStudent = rs[rsIds.indexOf(studentId)];
                return { ...state, currentStudent };
            } else {
                return { ...state };
            }
        }

        case fromActions.ActionTypes.SAVE_RELEVANT_CURRICULUM_OPTIONS: {
            const payload: rt.ISaveRelevantCurriculumElementsPayload =
                action.payload;
            const { relevantCurriculumOptions } = payload;

            return { ...state, relevantCurriculumOptions };
        }

        case fromActions.ActionTypes.UPDATE_QALL_QUIZZES_VARIABLES: {
            const payload: rt.ISaveQuizFilter = action.payload;
            const roleNodeId = payload.roleNodeId;
            const atv = {
                ...state.allQuizzesVariables,
                [roleNodeId]: payload.variables,
            };

            return { ...state, allQuizzesVariables: atv };
        }

        case fromActions.ActionTypes.SAVE_QUIZZES_SHOW_FILTER_STATE: {
            const payload: rt.IShowQuizzesFilter = action.payload;
            const { showQuizzesFilter } = payload;

            return { ...state, showQuizzesFilter };
        }

        // case fromActions.ActionTypes.SAVE_QUIZ_FILTERED_QUESTIONS_VARIABLES: {
        //     const payload: rt.ISaveQuizFilteredQuestions = action.payload;
        //     const { roleNodeId, variables } = payload;

        //     const qfv = {
        //         ...state.quizFilteredQVariables,
        //         [roleNodeId]: variables,
        //     };

        //     return { ...state, quizFilteredQVariables: qfv };
        // }

        case fromActions.ActionTypes.SAVE_SUBJECT_AREA: {
            const payload: rt.ISaveCurrentSubjectArea = action.payload;
            return { ...state, subjectAreaData: payload };
        }

        case fromActions.ActionTypes.SET_ACTIVE_LANGUAGE: {
            const payload: rt.ISetCurrentLanguage = action.payload;
            return { ...state, language: payload.language };
        }

        case fromActions.ActionTypes.SAVE_ROLE_PREFERENCES: {
            const payload: rt.ISaveRolePreferencesPayload = action.payload;
            // only update preferences for active role (potential race condition check)
            if (
                state.roles &&
                state.activeRole &&
                payload.activeRoleId === state.activeRole.id
            ) {
                const updatedRole: userRole.IUserRole = JSON.parse(
                    JSON.stringify(state.activeRole)
                );
                updatedRole.userRoleConfidentialById.rolePreferences = {
                    ...updatedRole.userRoleConfidentialById.rolePreferences,
                    ...payload.newPreferences,
                };
                const oldRoles = state.roles.filter(
                    (r) => r.id !== updatedRole.id
                );

                const newRoles = [...oldRoles, updatedRole];

                return {
                    ...state,
                    activeRole: updatedRole,
                    roles: newRoles,
                };
                // epic will update the database
            } else {
                return state;
            }
        }

        case fromActions.ActionTypes.SAVE_IP_DATA: {
            const payload: rt.ISaveIpDataPayload = action.payload;
            return {
                ...state,
                geoData: payload.response,
            };
        }

        case fromActions.ActionTypes.SAVE_SIGNUP_RESPONSE: {
            const payload: rt.ISaveSignUpResponse = action.payload;
            return {
                ...state,
                signUpResponse: payload,
            };
        }

        case fromActions.ActionTypes.SAVE_AUTH_STATUS: {
            const payload: rt.ISaveAuthStatus = action.payload;
            return {
                ...state,
                authStatus: payload.authStatus,
            };
        }

        case fromActions.ActionTypes.SET_I18N_DONE: {
            const payload: rt.ISetI18nDone = action.payload;
            return {
                ...state,
                i18nDone: payload.i18nDone,
            };
        }

        case fromActions.ActionTypes.SAVE_SIGN_UP_PARAMS: {
            const payload: rt.ISaveSignUpPayload = action.payload;
            return {
                ...state,
                signUpParams: payload,
            };
        }

        case fromActions.ActionTypes.SAVE_RAW_COGNITO_USER: {
            const payload: rt.IRawCognitoUser = action.payload;
            let userFound = true;
            try {
                const returnedUsername =
                    payload.rawCognitoUser.challengeParam.USERNAME;
                if (
                    returnedUsername &&
                    returnedUsername.length === 36 &&
                    returnedUsername[8] === "-"
                ) {
                    userFound = false;
                    showToast(
                        xtx(
                            "User not found.  Please check the email address to see if it is correct and either, press 'Start over' to fix a misspelling or register if it is correct",
                            "signup.user_not_found"
                        ),
                        20000,
                        Intent.PRIMARY
                    );
                }
            } catch (e) {
                // allow it to continue
            }
            return {
                ...state,
                rawCognitoUser: payload.rawCognitoUser,
                userFound,
            };
        }

        case fromActions.ActionTypes.UPDATE_SAVED_QUIZ_DATA: {
            const payload: rt.IScoreUpdate = action.payload;
            const sqd: rt.ISavedQuizData = JSON.parse(
                JSON.stringify(state.savedQuizData || {})
            );
            const quizIdString = payload.quizId.toString();
            if (sqd[quizIdString] !== undefined) {
                sqd[quizIdString][payload.questionId.toString()] = {
                    questionId: payload.questionId,
                    reversed: payload.reversed,
                    answered: payload.answered,
                    answer: payload.answer,
                    score: payload.score,
                    markedAsTooDifficult: payload.markedAsTooDifficult,
                    markedAsTooDifficultWhy: payload.markedAsTooDifficultWhy,
                };
            } else {
                sqd[quizIdString] = {
                    [payload.questionId.toString()]: {
                        questionId: payload.questionId,
                        reversed: payload.reversed,
                        answered: payload.answered,
                        answer: payload.answer,
                        score: payload.score,
                        markedAsTooDifficult: payload.markedAsTooDifficult,
                        markedAsTooDifficultWhy:
                            payload.markedAsTooDifficultWhy,
                    },
                };
            }
            return {
                ...state,
                savedQuizData: sqd,
            };
        }

        case fromActions.ActionTypes.CLEAR_SAVED_QUIZ_DATA: {
            const payload: rt.IClearSavedQuizData = action.payload;
            const sqd: rt.ISavedQuizData = JSON.parse(
                JSON.stringify(state.savedQuizData || {})
            );
            delete sqd[payload.quizId];
            return {
                ...state,
                savedQuizData: sqd,
            };
        }

        case fromActions.ActionTypes.SAVE_FILTERED_QUESTIONS: {
            const payload: rt.ISaveFilteredQuestions = action.payload;
            return {
                ...state,
                filteredQuestionsData: payload.filteredQuestionsData,
            };
        }

        case fromActions.ActionTypes.SAVE_WS_UUID: {
            const payload: rt.IWsConnect = action.payload;
            return {
                ...state,
                wsUuid: payload.userUuid,
            };
        }

        case fromActions.ActionTypes.WEBSOCKET_CONNECTED: {
            return {
                ...state,
                wsConnected: true,
            };
        }

        case fromActions.ActionTypes.WEBSOCKET_DISCONNECTED: {
            return {
                ...state,
                wsConnected: false,
            };
        }

        case fromActions.ActionTypes.MAINTAIN_WS: {
            return {
                ...state,
                maintainWs: true,
            };
        }

        case fromActions.ActionTypes.DO_NOT_MAINTAIN_WS: {
            return {
                ...state,
                maintainWs: false,
            };
        }

        case fromActions.ActionTypes.SET_FAIL_TO_FETCH_COUNT: {
            return {
                ...state,
                failToFetchCount: action.payload.count,
            };
        }

        case fromActions.ActionTypes.ADD_TO_QUIZ_COMPLETIONS: {
            const payload: rt.IAddToQuizCompletions = action.payload;
            const recentQuizCompletions = [
                ...(state.recentQuizCompletions || []),
                payload.quizId,
            ];
            return {
                ...state,
                recentQuizCompletions,
            };
        }

        case fromActions.ActionTypes.SET_CURRENT_COLLECTION_INFO: {
            return {
                ...state,
                currentCollectionInfo: {
                    ...action.payload,
                },
            };
        }

        case fromActions.ActionTypes.CLEAR_MY_QUESTION_LIST: {
            return produce(state, (draft) => {
                if (!draft.questionListByRole) {
                    draft.questionListByRole = {};
                }
                draft.questionListByRole[action.payload.roleId.toString()] = [];
            });
        }

        case fromActions.ActionTypes.ADD_TO_MY_QUESTION_LIST: {
            const roleIdString = action.payload.roleId.toString();
            return produce(state, (draft) => {
                if (!draft.questionListByRole) {
                    draft.questionListByRole = {};
                }
                if (!draft.questionListByRole[roleIdString]) {
                    draft.questionListByRole[roleIdString] = [];
                }
                draft.questionListByRole[roleIdString].push(
                    action.payload.questionId
                );
            });
        }

        case fromActions.ActionTypes.REMOVE_FROM_MY_QUESTION_LIST: {
            const roleIdString = action.payload.roleId.toString();
            return produce(state, (draft) => {
                if (!draft.questionListByRole) {
                    draft.questionListByRole = {};
                }
                if (!draft.questionListByRole[roleIdString]) {
                    draft.questionListByRole[roleIdString] = [];
                }
                const foundIndex = draft.questionListByRole[
                    roleIdString
                ].indexOf(action.payload.questionId);
                if (foundIndex !== -1) {
                    draft.questionListByRole[roleIdString].splice(
                        foundIndex,
                        1
                    );
                }
            });
        }

        default:
            return state;
    }
};
