import update from "immutability-helper";
import intl from "react-intl-universal";

import { Colors } from "src/Resources";
import { IQuestionWithCE } from "src/app/graphql/types_and_fragments/question";
import { FunctionType, IItem } from "src/app/types";

import Styles from "src/design-components/CollectionQuestion/Styles.module.scss";
import { IQuizExtendedWithAuto } from "src/app/graphql/types_and_fragments/quiz";
import { ICeFull } from "src/app/graphql/types_and_fragments/curriculumElement";
// import { uuidRegex } from "src/Resources/Constants";
import parentLogger from "src/app/AppSupport/Utils/loggerService";

const logger = parentLogger.child({
    module: "QuestionUtils",
});

const xtx = (str: string, key?: string) => intl.get(key || str || "x") || str;

export const reorderQuestion = (
    collectionQuestions: IQuestionWithCE[],
    setCollectionQuestions: FunctionType,
    callbackFn: FunctionType,
    questionIdToMove,
    newLocation,
    itemBeingMoved: IQuestionWithCE
) => {
    logger.info(
        `${collectionQuestions.length} questions; move q ${questionIdToMove} to position ${newLocation}`
    );
    const movedQuestionLocation = collectionQuestions
        .map((cq) => cq.id)
        .indexOf(questionIdToMove);
    const movedQuestion = collectionQuestions[movedQuestionLocation];
    const newQs = update(collectionQuestions, {
        $splice: [
            [movedQuestionLocation, 1],
            [newLocation, 0, movedQuestion],
        ],
    });
    // @ts-ignore
    if (newQs.indexOf(undefined) !== -1) {
        logger.info("stop here");
    }
    setCollectionQuestions(newQs);
    callbackFn();
};

export const findQuestionIndex = (
    collectionQuestions: IQuestionWithCE[],
    id: number
) => {
    // N.B. could be done without the map, but doing it direct to avoid any race condition
    // on currentCollQIds
    // const foundIndex = collectionQuestions.map((q) => q.id).indexOf(id);
    return collectionQuestions.map((q) => q.id).indexOf(id);
};

interface IGridSetDetails {
    style: any;
    hide: string[];
    editStyle?: any;
}

export type GridSetKeyType =
    | "wideNoAnswer"
    | "wideWithAnswers"
    | "narrowSmall"
    | "narrowFull"
    | "preview"
    | "wideBulkEdit";

export interface IGridSetToStyle {
    wideNoAnswer: IGridSetDetails;
    wideWithAnswers: IGridSetDetails;
    narrowSmall: IGridSetDetails;
    narrowFull: IGridSetDetails;
    preview: IGridSetDetails;
    wideBulkEdit: IGridSetDetails;
}

export const gridSetToStyle: IGridSetToStyle = {
    wideBulkEdit: {
        style: Styles.gridWideBulkEdit,
        hide: [
            "questionimage",
            "answer",
            "answerimagethumbnail",
            "answerimage",
            "hint",
            "alwaysinanswer",
            "mylistbutton",
        ],
        editStyle: Styles.gridWideBulkEdit,
    },
    wideNoAnswer: {
        style: Styles.gridWideNoAnswer,
        hide: [
            "questionimagethumbnail",
            "answer",
            "answerimagethumbnail",
            "answerimage",
        ],
        editStyle: Styles.gridWideEdit,
    },
    wideWithAnswers: {
        style: Styles.gridWideWithAnswers,
        hide: ["questionimagethumbnail", "answerimagethumbnail"],
        editStyle: Styles.gridWideEdit,
    },
    narrowSmall: {
        style: Styles.gridNarrowSmall,
        hide: [
            "questionimage",
            "answer",
            "answerimagethumbnail",
            "answerimage",
            "ce",
            "hint",
            "mylistbutton",
        ],
        editStyle: Styles.gridNarrowEdit,
    },
    narrowFull: {
        style: Styles.gridNarrowFull,
        hide: ["questionimage", "answerimage", "ce", "mylistbutton"],
        editStyle: Styles.gridNarrowEdit,
    },
    preview: {
        style: Styles.gridPreview,
        hide: [
            "questionimagethumbnail",
            "answerimagethumbnail",
            "ce",
            "mylistbutton",
        ],
    },
};

export const computeAdjQuizName = (q: IQuizExtendedWithAuto, language) => {
    const newq = { ...q };
    if (newq.quizName.length === 36 && newq.quizName[8] === "-") {
        const ad =
            newq.autoQuizVariableSetByAutoQuizVariableSetId.additionalData;
        if (ad && ad.review_lookback_start) {
            // hack to determine if this is a review
            // make new title & change newq
            const newTitle = `${xtx(
                "Review of learning",
                "role_home.review"
            )} ${xtx(
                ad.sequence_detail_comment,
                `sequence.${ad.sequence_detail_comment}`
            )}`;
            newq.quizName = newTitle;

            try {
                const reviewStartDate = new Date(
                    parseInt(
                        newq.autoQuizVariableSetByAutoQuizVariableSetId
                            .earliestResults,
                        10
                    ) -
                        ad.review_lookback_start * 86400000
                );
                const reviewEndDate = new Date(
                    parseInt(
                        newq.autoQuizVariableSetByAutoQuizVariableSetId
                            .earliestResults,
                        10
                    ) -
                        ad.review_lookback_end * 86400000
                );
                const newSubtitle = `${xtx(
                    "Material learned between",
                    "role_home.material_learned_between"
                )} ${new Intl.DateTimeFormat(language || "en-US", {
                    month: "numeric",
                    day: "numeric",
                }).format(reviewStartDate)} and ${new Intl.DateTimeFormat(
                    language || "en-US",
                    {
                        month: "numeric",
                        day: "numeric",
                    }
                ).format(reviewEndDate)}`; // TODO - finish

                newq.quizSubtitle = newSubtitle;
            } catch {
                newq.quizSubtitle = "";
            }
        }
    }
    return newq;
};

export const makeCelements = (curriculumNodes: ICeFull[]) => {
    const items: IItem[] = [];
    [...curriculumNodes]
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .forEach((ce) => {
            if (
                ce.elementParentId !== undefined &&
                ce.elementParentId !== null
            ) {
                // @ts-ignore
                const spaceVals: number[] = items
                    .filter((i) => i.id === ce.elementParentId)
                    .map((i) =>
                        i.userDataObject ? i.userDataObject.numSpaces : 0
                    );
                const spaces =
                    spaceVals.length > 0 ? Math.max(...spaceVals) + 2 : 2;
                items.push({
                    id: ce.id,
                    name: `${".".repeat(spaces - 2)}${
                        ce.chapter ? `${ce.chapter}.` : ""
                    }${
                        ce.section ? `${ce.section}.` : ""
                    } ${ce.elementText.trim()}`,
                    userDataObject: {
                        lft: ce.lft,
                        rgt: ce.rgt,
                        elementParentId: ce.elementParentId,
                        chapter: ce.chapter,
                        section: ce.section,
                        numSpaces: spaces,
                    },
                });
            } else {
                // is the top-level curriculum element (for the class)
                items.push({
                    id: ce.id,
                    name: ce.elementText.trim(),
                    userDataObject: {
                        lft: ce.lft,
                        rgt: ce.rgt,
                        elementParentId: ce.elementParentId,
                        chapter: ce.chapter,
                        section: ce.section,
                        numSpaces: 0,
                    },
                });
            }
        });
    return items;
};

export const getDifficultyLevelColor = (difficultyLevel) => {
    switch (difficultyLevel) {
        case 1:
            return Colors.difficulty_1;
        case 2:
            return Colors.difficulty_2;
        case 3:
            return Colors.difficulty_3;
        case 4:
            return Colors.difficulty_4;
        case 5:
            return Colors.difficulty_5;
        case 6:
            return Colors.difficulty_6;
        default:
            return Colors.difficulty_7;
    }
};
