/** @format */

import { Intent } from "@blueprintjs/core";
import { API, Storage } from "aws-amplify";

import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import intl from "react-intl-universal";

import {
    apolloClientInstance as client,
    prepareDbUserData,
    augmentRole,
} from "src/app/AppSupport/Utils";
import {
    SET_LATEST_ROLE,
    IMSetLatestRoleResult,
} from "src/app/graphql/MSetLatestRole";
import { ActionTypes } from "../app_actions";
import {
    IChooseRole,
    ICreateRole,
    IStore,
    IUploadProtectedFile,
    ISaveRolesData,
    ISignUpPayload,
    ISaveRolePreferencesPayload,
    IRequestSharingSetupPayload,
    IRespondToInvitation,
    IUpdateUserAndRoleInfoPayload,
    ISendInvitationsPayload,
    IQuestionListPayload,
    IClearQuestionListPayload,
    // IValidateWithToken,
} from "../reducer_types";
import {
    GET_ONE_SUBJECT_AREA,
    IOneSubjAreaData,
} from "src/app/graphql/QOneSubjectArea";
import { UPDATE_USER_AND_ROLE_INFO } from "src/app/graphql/MSetRolePreferences";
import {
    CREATE_NEW_ROLE,
    ICreateNewRoleResult,
    // ICreateNewRoleInput,
} from "src/app/graphql/MCreateNewRole";
import { RESPOND_TO_INVITATION } from "src/app/graphql/MRespondToInvitation";
import {
    GET_UPDATED_USER_INFO,
    GET_USER_SUBJ_AND_DB_INFO,
} from "src/app/graphql/QUserInfo";
import { stringIsNullOrEmpty } from "src/app/AppSupport/StringTools";

const xtx = (str: string, key?: string) => intl.get(key || str || "x") || str;

interface ISaveRolesDataAction {
    type: string;
    payload: ISaveRolesData;
}

// TODO: fix the epic below - it is a query not a mutation
const saveRolesDataEpic = (
    action$: ActionsObservable<ISaveRolesDataAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.SAVE_ROLES_DATA),
        map((action) => {
            const payload = action.payload;
            const { roles, lastRoleId } = payload;
            if (state$.value.app.activeRole || roles.length === 0) {
                return {
                    type: "EPIC_COMPLETED",
                    payload: {},
                };
            } else {
                const unexpiredRoles = roles.filter((role) => !role.expired);
                const activeRoleList = unexpiredRoles.filter((role) => {
                    return role.id === lastRoleId;
                });
                if (unexpiredRoles.length) {
                    const activeRole = activeRoleList.length
                        ? activeRoleList[0]
                        : unexpiredRoles[0];
                    return {
                        type: "CHOOSE_ROLE",
                        payload: {
                            roleId: activeRole.id,
                        },
                    };
                } else {
                    return {
                        type: "EPIC_COMPLETED",
                        payload: {},
                    };
                }
            }
        })
    );
interface IGetSubjecAreaAction {
    type: string;
    payload: {};
}

// TODO: fix the epic below - it is a query not a mutation
const getSubjectAreaEpic = (
    action$: ActionsObservable<IGetSubjecAreaAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.GET_SUBJECT_AREA),
        mergeMap((action) =>
            from(
                client.query({
                    query: GET_ONE_SUBJECT_AREA,
                    variables: {
                        specificSubjectId:
                            state$.value.app.activeRole
                                .specificSubjectBySpecificSubjectId.id,
                    },
                })
            ).pipe(
                map((res) => {
                    if (res && res.data) {
                        const sadata: IOneSubjAreaData = res.data;
                        return {
                            payload: sadata,
                            type: ActionTypes.SAVE_SUBJECT_AREA,
                        };
                    } else {
                        return {
                            payload: {
                                epicError: {
                                    message: "Failed to get subject area data",
                                },
                            },
                            type: ActionTypes.EPIC_ERROR,
                        };
                    }
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IChooseRoleAction {
    type: string;
    payload: IChooseRole;
}

const chooseRoleEpic = (
    action$: ActionsObservable<IChooseRoleAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.CHOOSE_ROLE),
        mergeMap((action) =>
            client.mutate({
                mutation: SET_LATEST_ROLE,
                variables: { input: { vRoleId: action.payload.roleId } },
            })
        ),
        mergeMap((response) => {
            const nextActions: { type: string; payload: any }[] = [
                {
                    type: ActionTypes.GET_SUBJECT_AREA,
                    payload: {},
                },
            ];
            const responseData: IMSetLatestRoleResult = response.data;
            const tRPrefs =
                responseData.setLatestRole.ctUserRole.userRoleConfidentialById
                    .rolePreferences;
            const roleLanguage =
                tRPrefs && tRPrefs.language && tRPrefs.language;
            const userPrefs = state$.value.app.dbUser.userPreferences;
            const userLanguage = userPrefs && userPrefs.language;
            if ((roleLanguage || userLanguage) !== state$.value.app.language) {
                // change app language to match role's language
                nextActions.push({
                    type: ActionTypes.REQUEST_NEW_LANGUAGE,
                    payload: { language: roleLanguage || userLanguage },
                });
                // N.B. leaving this out since setting a role language ought to require a positive action
                // } else if (!tRPrefs || !tRPrefs.language) {
                //     // set the role pref to the current language
                //     nextActions.push({
                //         type: ActionTypes.SAVE_ROLE_LANGUAGE_PREFERENCE,
                //         payload: {
                //             roleId: responseData.setLatestRole.ctUserRole.id,
                //             language: state$.value.app.language,
                //         },
                //     });
            }
            return nextActions;
        })
    );

interface IUploadProtectedFileAction {
    type: string;
    payload: IUploadProtectedFile;
}

const uploadProtectedFileEpic = (
    action$: ActionsObservable<IUploadProtectedFileAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.UPLOAD_PROTECTED_FILE),
        mergeMap((action) => {
            return from(
                Storage.put(action.payload.uuidFilename, action.payload.file, {
                    contentType: action.payload.file.type,
                    level: "protected",
                })
            ).pipe(
                mergeMap(() => {
                    const completedUploadAction = {
                        type: ActionTypes.SHOW_TOAST,
                        payload: {
                            toastIntent: Intent.PRIMARY,
                            toastMessage: xtx(
                                "Image stored",
                                "epics.image_stored"
                            ),
                            toastMs: 2000,
                        },
                    };
                    if (action.payload.followUpAction) {
                        if (action.payload.roleOwner) {
                            const itemKey = `aws.cognito.identity-id.${process.env.REACT_APP_IDENTITY_POOL_ID}`;
                            const identityId = localStorage.getItem(itemKey);
                            if (identityId !== null) {
                                const payload = {
                                    ...action.payload.followUpAddlPayload,
                                    identityId,
                                    roleOwner: action.payload.roleOwner,
                                };
                                return [
                                    completedUploadAction,
                                    {
                                        payload,
                                        type: action.payload.followUpAction,
                                    },
                                ];
                            } else {
                                return [
                                    completedUploadAction,
                                    {
                                        payload: {
                                            errorMessage: {
                                                message:
                                                    "No identityId found in local storage",
                                                source: "UPLOAD_PROTECTED_FILE",
                                            },
                                        },
                                        type: ActionTypes.EPIC_SENTRY_MESSAGE,
                                    },
                                ];
                            }
                        } else {
                            return [
                                completedUploadAction,
                                {
                                    payload: {
                                        errorMessage: {
                                            message: `No roleOwner provided; cannot enter file info into database;
                                                  file uploaded, however; TODO - only upload if this check succeeds`,
                                            source: "UPLOAD_PROTECTED_FILE",
                                        },
                                    },
                                    type: ActionTypes.EPIC_SENTRY_MESSAGE,
                                },
                            ];
                        }
                    } else {
                        return [completedUploadAction];
                    }
                })
            );
        })
    );

interface ISignUpEpic {
    type: string;
    payload: ISignUpPayload;
}

const signUpEpic = (
    action$: ActionsObservable<ISignUpEpic>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.SIGN_UP),
        mergeMap((action) =>
            from(
                API.post("valearnmain", "/signup", {
                    body: action.payload,
                })
            ).pipe(
                map((response) => {
                    // TODO: think about how to handle return info
                    // check for errors and save status in redux: response.success, response.body.error, response.body.errorType
                    // update recaptcha score in redux: response.body.recaptchaScore
                    console.log("in response after signup");
                    return {
                        payload: response,
                        type: ActionTypes.SAVE_SIGNUP_RESPONSE,
                    };
                }),
                catchError((err) => {
                    console.log("got error in /signup in API");
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface ISendInvitationsEpic {
    type: string;
    payload: ISendInvitationsPayload;
}

const sendInvitationsEpic = (
    action$: ActionsObservable<ISendInvitationsEpic>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.SEND_INVITATIONS),
        mergeMap((action) =>
            from(
                API.post("valearnmain", "/check-invitations", {
                    body: action.payload,
                })
            ).pipe(
                map((response) => {
                    return {
                        type: ActionTypes.EPIC_COMPLETED,
                        payload: response,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IRequestSharingSetupEpic {
    type: string;
    payload: IRequestSharingSetupPayload;
}

const requestSharingSetupEpic = (
    action$: ActionsObservable<IRequestSharingSetupEpic>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.REQUEST_SHARING_SETUP),
        mergeMap((action) =>
            from(
                API.post("valearnmain", "/requestSharingSetup", {
                    body: action.payload,
                })
            ).pipe(
                map((response) => {
                    return {
                        type: ActionTypes.SHOW_TOAST,
                        payload: {
                            toastIntent: Intent.SUCCESS,
                            toastMessage: `response: ${JSON.stringify(
                                response
                            )}`,
                            toastMs: 0,
                        },
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.SHOW_TOAST,
                        payload: {
                            toastIntent: Intent.DANGER,
                            toastMessage: `response: ${JSON.stringify(err)}`,
                            toastMs: 0,
                        },
                    });
                    // return of({
                    //     type: ActionTypes.EPIC_ERROR,
                    //     payload: { errorData: err },
                    // });
                })
            )
        )
    );

// interface IValidateWithTokenEpic {
//     type: string;
//     payload: IValidateWithToken;
// }

// const validateWithTokenEpic = (
//     action$: ActionsObservable<IValidateWithTokenEpic>,
//     state$: StateObservable<IStore>
// ) =>
//     action$.pipe(
//         ofType(ActionTypes.VALIDATE_WITH_TOKEN),
//         mergeMap((action) =>
//             from(
//                 API.post("valearnmain", "/validate", {
//                     body: action.payload,
//                 })
//             ).pipe(
//                 map((response) => {
//                     console.log(response);
//                     if (response.success) {
//                         return {
//                             type: ActionTypes.SHOW_TOAST,
//                             payload: {
//                                 toastIntent: Intent.SUCCESS,
//                                 toastMessage: t("Validation succeeded", "epics.validation_succeeded"),
//                                 toastMs: 5000,
//                             },
//                         };
//                     } else {
//                         const rawMessage = response.message || "";
//                         // TODO - add language t()
//                         const messageToUse =
//                             rawMessage.toLowerCase().indexOf("timed out") !== -1
//                                 ? "Validation link has expired.  Please check your email for a newer one or email us at info@valearn.com"
//                                 : rawMessage;
//                         return {
//                             type: ActionTypes.SHOW_TOAST,
//                             payload: {
//                                 toastIntent: Intent.DANGER,
//                                 toastMessage: messageToUse,
//                                 toastMs: 0,
//                             },
//                         };
//                     }
//                 }),
//                 catchError((err) => {
//                     return of({
//                         type: ActionTypes.EPIC_ERROR,
//                         payload: { errorData: err },
//                     });
//                 })
//             )
//         )
//     );

interface ISetRolePreferencesAction {
    type: string;
    payload: ISaveRolePreferencesPayload;
}

const setRolePreferencesEpic = (
    action$: ActionsObservable<ISetRolePreferencesAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.SAVE_ROLE_PREFERENCES),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: UPDATE_USER_AND_ROLE_INFO,
                    variables: {
                        input: {
                            vRoleId: action.payload.activeRoleId,
                            vRolePreferences: action.payload.newPreferences,
                        },
                    },
                })
            ).pipe(
                map(() => {
                    return {
                        type: ActionTypes.CAPTURE_APP_ROLE_EVENT,
                        payload: {
                            variables: {
                                vAppEventCategory: "user",
                                vAppEventName: "save_user_prefs",

                                vAppEventData: {
                                    prefsAdded: action.payload.newPreferences,
                                },
                            },
                        },
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IHandleUpdatedQuestionListAction {
    type: string;
    payload: IQuestionListPayload | IClearQuestionListPayload;
}

const updateQuestionListEpic = (
    action$: ActionsObservable<IHandleUpdatedQuestionListAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(
            ActionTypes.CLEAR_MY_QUESTION_LIST,
            ActionTypes.ADD_TO_MY_QUESTION_LIST,
            ActionTypes.REMOVE_FROM_MY_QUESTION_LIST
        ),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: UPDATE_USER_AND_ROLE_INFO,
                    variables: {
                        input: {
                            vRoleId: action.payload.roleId,
                            vRolePreferences: {
                                questionList: state$.value.app
                                    .questionListByRole
                                    ? state$.value.app.questionListByRole[
                                          action.payload.roleId
                                      ]
                                    : [],
                            },
                        },
                    },
                })
            ).pipe(
                map(() => {
                    return {
                        type: ActionTypes.CAPTURE_APP_ROLE_EVENT,
                        payload: {
                            variables: {
                                vAppEventCategory: "user",
                                vAppEventName: "save_user_prefs",

                                vAppEventData: {
                                    prefsAdded: {
                                        questionList: state$.value.app
                                            .questionListByRole
                                            ? state$.value.app
                                                  .questionListByRole[
                                                  action.payload.roleId
                                              ]
                                            : [],
                                    },
                                },
                            },
                        },
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface ICreateRoleAction {
    type: string;
    payload: ICreateRole;
}

const createRoleEpic = (
    action$: ActionsObservable<ICreateRoleAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.CREATE_ROLE),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: CREATE_NEW_ROLE,
                    variables: action.payload.variables,
                })
            ).pipe(
                mergeMap((res) => {
                    const data: ICreateNewRoleResult = res.data;
                    const roleResult = data.createNewRoleProtected.role;
                    const role = augmentRole(roleResult);
                    const nextActions: { type: string; payload: any }[] = [];
                    nextActions.push({
                        payload: {
                            variables: {
                                vAppEventCategory: "user",
                                vAppEventName: "create_role",

                                vAppEventData: {
                                    prefsAdded: action.payload.variables,
                                },
                            },
                        },
                        type: ActionTypes.CAPTURE_APP_ROLE_EVENT,
                    });
                    nextActions.push({
                        type: ActionTypes.SAVE_ONE_ROLE,
                        payload: { role },
                    });
                    nextActions.push({
                        type: ActionTypes.SEND_INVITATIONS,
                        payload: { invited_by_role: role.id },
                    });
                    nextActions.push({
                        type: ActionTypes.SHOW_TOAST,
                        payload: {
                            toastIntent: Intent.SUCCESS,
                            toastMessage: `New role added successfully`,
                            toastMs: 5000,
                        },
                    });

                    return nextActions;
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IRespondToInvitationAction {
    type: string;
    payload: IRespondToInvitation;
}

const respondToInvitationEpic = (
    action$: ActionsObservable<IRespondToInvitationAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.RESPOND_TO_INVITATION),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: RESPOND_TO_INVITATION,
                    variables: {
                        input: action.payload,
                    },
                    refetchQueries: [
                        {
                            query: GET_USER_SUBJ_AND_DB_INFO,
                            variables: {
                                cognitoSub: state$.value.app.user.cognitoSub,
                            },
                        },
                    ],
                })
            ).pipe(
                mergeMap((res) => {
                    const nextActions: { type: string; payload: any }[] = [];
                    nextActions.push({
                        payload: {
                            variables: {
                                vAppEventCategory: "user",
                                vAppEventName: "respond_to_invitation",

                                vAppEventData: {
                                    prefsAdded: action.payload,
                                },
                            },
                        },
                        type: ActionTypes.CAPTURE_APP_ROLE_EVENT,
                    });
                    // nextActions.push({
                    //     type: ActionTypes.REFRESH_ROLES,
                    //     payload: {},
                    // });
                    let toastMessage: string;
                    if (action.payload.vAccept) {
                        toastMessage = "Invitation accepted and new role added";
                    } else {
                        toastMessage = "Invitation rejected";
                    }
                    nextActions.push({
                        type: ActionTypes.SHOW_TOAST,
                        payload: {
                            toastIntent: Intent.SUCCESS,
                            toastMessage,
                            toastMs: 5000,
                        },
                    });

                    return nextActions;
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IUpdateUserAndRoleInfoEpic {
    type: string;
    payload: IUpdateUserAndRoleInfoPayload;
}

const extractVariablesForUpdateUserAndRoleInfoEpic = (action) => {
    const actionCopy = { ...action };
    delete actionCopy.payload.toastMessage;
    return {
        input: actionCopy.payload,
        roleId: actionCopy.payload.vRoleId,
    };
};

const updateUserAndRoleInfoEpic = (
    action$: ActionsObservable<IUpdateUserAndRoleInfoEpic>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.UPDATE_USER_AND_ROLE_INFO),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: UPDATE_USER_AND_ROLE_INFO,
                    variables:
                        extractVariablesForUpdateUserAndRoleInfoEpic(action),
                })
            ).pipe(
                mergeMap((res) => {
                    const nextActions: { type: string; payload: any }[] = [];
                    const { dbUser, roles } = prepareDbUserData(
                        res.data.updateUserOrRoleInfo.ctUser,
                        false
                    );
                    nextActions.push({
                        payload: {
                            dbUser,
                            roles,
                        },
                        type: ActionTypes.SAVE_ROLES_DATA,
                    });
                    nextActions.push({
                        payload: {
                            variables: {
                                vAppEventCategory: action.payload.vUserId
                                    ? "user"
                                    : "role",
                                vAppEventName: "update_user_and_role_info",
                                vAppEventData: {
                                    prefsAdded: action.payload,
                                },
                            },
                        },
                        type: ActionTypes.CAPTURE_APP_ROLE_EVENT,
                    });

                    if (!stringIsNullOrEmpty(action.payload.toastMessage)) {
                        nextActions.push({
                            type: ActionTypes.SHOW_TOAST,
                            payload: {
                                toastIntent: Intent.SUCCESS,
                                toastMessage: action.payload.toastMessage,
                                toastMs: 5000,
                            },
                        });
                    }

                    return nextActions;
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IRefreshRolesAction {
    type: string;
    payload: {};
}

const refreshRolesEpic = (
    action$: ActionsObservable<IRefreshRolesAction>,
    state$: StateObservable<IStore>
) => {
    return action$.pipe(
        ofType(ActionTypes.REFRESH_ROLES),
        mergeMap((action) =>
            from(
                client.query({
                    query: GET_UPDATED_USER_INFO,
                    variables: {
                        cognitoSub: state$.value.app.dbUser.cognitoSub,
                    },
                })
            ).pipe(
                map((res) => {
                    const { dbUser, roles } = prepareDbUserData(
                        res.data.ctUser,
                        false
                    );
                    return {
                        payload: {
                            dbUser,
                            roles,
                            invitations: res.data.invitations.nodes,
                        },
                        type: ActionTypes.SAVE_ROLES_DATA,
                    };
                }),
                catchError((err) => {
                    console.log(err);
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );
};

const defaultExports = [
    saveRolesDataEpic,
    chooseRoleEpic,
    uploadProtectedFileEpic,
    getSubjectAreaEpic,
    setRolePreferencesEpic,
    signUpEpic,
    // validateWithTokenEpic,
    requestSharingSetupEpic,
    createRoleEpic,
    respondToInvitationEpic,
    updateUserAndRoleInfoEpic,
    refreshRolesEpic,
    sendInvitationsEpic,
    updateQuestionListEpic,
];

export default defaultExports;
