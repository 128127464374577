/** @format */

import gql from "graphql-tag";

// import { IGeoData } from "../../../redux/reducer_types";

export const CAPTURE_APP_ROLE_EVENT = gql`
    mutation addUserEvent($input: AddRoleAppEventInput!) {
        addRoleAppEvent(input: $input) {
            clientMutationId
        }
    }
`;

export interface IResult {
    addRoleAppEvent: {
        clientMutationId: string | null;
    };
}

export interface IRoleAppEventVars {
    vAppEventCategory: string;
    vAppEventName: string;
    vAppEventData?: any;
}

interface IFinalRoleAppEventVars extends IRoleAppEventVars {
    vUserId?: number;
    vRoleOwner?: number;
    vGeoData?: any; // leaving this out for the time being
}

export interface IVariables {
    input: {
        roleAppEvent: IFinalRoleAppEventVars;
    };
}
