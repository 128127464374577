import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { Intent } from "@blueprintjs/core";
import intl from "react-intl-universal";

import { ActionTypes } from "../app_actions";
import {
    IStore,
    IPersistQuizProgress,
    IStopQuizzes,
    IGradeQuiz,
} from "../reducer_types";
import { GRADE_QUIZ } from "src/app/graphql/MGradeQuiz";
import { RETRIEVE_RELEVANT_QUIZZES } from "src/app/graphql/QFilteredQuizzes";
import { NON_STUDENT_RETRIEVE_RELEVANT_QUIZZES } from "src/app/graphql/QNonStudentRelevantQuizzes";
import { PERSIST_QUIZ_PROGRESS } from "src/app/graphql/MSaveAssignmentProgress";
import { STOP_QUIZZES } from "src/app/graphql/MStopQuizzes";

import {
    apolloClientInstance as client,
    getAssignmentProgressData,
    showToast,
    prepareScoresData,
} from "src/app/AppSupport/Utils";

const xtx = (str: string, key?: string) => intl.get(key || str || "x") || str;

interface IGradeQuizAction {
    type: string;
    payload: IGradeQuiz;
}

export const gradeQuizEpic = (
    action$: ActionsObservable<IGradeQuizAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.GRADE_QUIZ),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: GRADE_QUIZ,
                    variables: {
                        ...action.payload,
                        vScoresData: prepareScoresData(
                            state$.value.app.savedQuizData,
                            action.payload.vQuizId,
                            action.payload.selfAssessed
                        ),
                    },
                    refetchQueries: [
                        {
                            query:
                                state$.value.app.activeRole.userRoleConfidentialById.userRole.toLowerCase() ===
                                "student"
                                    ? RETRIEVE_RELEVANT_QUIZZES
                                    : NON_STUDENT_RETRIEVE_RELEVANT_QUIZZES,
                            variables: {
                                quizzesPerPageRequest: 25,
                                cursor: null,
                                vRoleId: action.payload.vActiveRoleId,
                                vStudentRoleIds: state$.value.app.activeRole
                                    .myStudents
                                    ? state$.value.app.activeRole.myStudents.nodes.map(
                                          (s) => s.id
                                      )
                                    : [],
                                vHideAutoQuizzesIfCompleted: true,
                            },
                        },
                    ],
                })
            ).pipe(
                map(() => {
                    showToast(
                        xtx(
                            "Scores saved.  If a new quiz was generated, it should appear now in the assignments section",
                            "epics.scores_saved"
                        ),
                        5000,
                        Intent.SUCCESS
                    );
                    return {
                        type: ActionTypes.CAPTURE_APP_ROLE_EVENT,
                        payload: {
                            variables: {
                                vAppEventCategory: "assignment",
                                vAppEventName: "grade_quiz",

                                vAppEventData: {
                                    quiz_id: action.payload.vQuizId,
                                },
                            },
                        },
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IPersistQuizProgressAction {
    type: string;
    payload: IPersistQuizProgress;
}

export const persistQuizProgressEpic = (
    action$: ActionsObservable<IPersistQuizProgressAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.PERSIST_QUIZ_PROGRESS),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: PERSIST_QUIZ_PROGRESS,
                    variables: {
                        ...action.payload,
                        vAsofdate: Date.now(),
                        vAssignmentProgressData: getAssignmentProgressData(
                            state$.value.app.savedQuizData,
                            action.payload.vQuizId
                        ),
                    },
                    refetchQueries: [
                        // N.B. in the future, we will note that a quiz has saved data
                        {
                            query: RETRIEVE_RELEVANT_QUIZZES,
                            variables: {
                                quizzesPerPageRequest: 25, // TODO: get rid of hardcoded value
                                cursor: null,
                                vRoleId: action.payload.vRoleId,
                                vHideAutoQuizzesIfCompleted: true,
                            },
                        },
                    ],
                })
            ).pipe(
                map(() => {
                    showToast(
                        xtx(
                            "Current assignment progress saved.",
                            "epics.assignment_progress_saved"
                        ),
                        5000,
                        Intent.SUCCESS
                    );
                    return {
                        type: ActionTypes.CAPTURE_APP_ROLE_EVENT,
                        payload: {
                            variables: {
                                vAppEventCategory: "assignment",
                                vAppEventName: "save_progress",

                                vAppEventData: {
                                    quiz_id: action.payload.vQuizId,
                                },
                            },
                        },
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IStopQuizAction {
    type: string;
    payload: IStopQuizzes;
}

export const stopQuizEpic = (
    action$: ActionsObservable<IStopQuizAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.STOP_QUIZZES),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: STOP_QUIZZES,
                    variables: {
                        input: {
                            ...action.payload,
                        },
                    },
                    refetchQueries: [
                        // N.B. in the future, we will note that a quiz has saved data
                        {
                            query: RETRIEVE_RELEVANT_QUIZZES,
                            variables: {
                                quizzesPerPageRequest: 25, // TODO: get rid of hardcoded value
                                cursor: null,
                                vRoleId: action.payload.vRoleId,
                                vHideAutoQuizzesIfCompleted: true,
                            },
                        },
                    ],
                })
            ).pipe(
                map(() => {
                    showToast(
                        xtx(
                            "No more quizzes to come from this assignment",
                            "epics.assignment_quizzes_stopped"
                        ),
                        5000,
                        Intent.SUCCESS
                    );
                    return {
                        type: ActionTypes.CAPTURE_APP_ROLE_EVENT,
                        payload: {
                            variables: {
                                vAppEventCategory: "assignment",
                                vAppEventName: "stop_quiz",

                                vAppEventData: {
                                    quiz_id: action.payload.vQuizId,
                                },
                            },
                        },
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

// N.B. the combineEpics function needs a list of epics

const defaultExports = [gradeQuizEpic, persistQuizProgressEpic, stopQuizEpic];
export default defaultExports;
