/** @format */

import gql from "graphql-tag";

export const PERSIST_QUIZ_PROGRESS = gql(`
mutation m2(
    $vRoleId: Int!,
    $vQuizId: Int!,
    $vAsofdate: BigInt!,
    $vExpiresAfter: BigInt,
    $vAssignmentProgressData: JSON!
    $clientMutationId: String
) {
    persistAssignmentProgress(input: {
        vRoleId: $vRoleId
        vQuizId: $vQuizId
        vAsofdate: $vAsofdate
        vExpiresAfter: $vExpiresAfter
        vAssignmentProgressData: $vAssignmentProgressData
        clientMutationId: $clientMutationId        
    }) {
        clientMutationId
    }
}
`);

export interface IData {
    clientMutationId: string;
}

export interface IPersistQuizVars {
    vRoleId: number;
    vQuizId: number;
    vAsofdate: number;
    vExpiresAfter?: number;
    vAssignmentProgressData: any; // json b
    clientMutationId?: string;
}
