const filterDistinct = (value, index, self) => {
    return self.indexOf(value) === index;
};

const distinctArrayOn = (arr, fieldName) => {
    if (arr) {
        const colArray = arr
            .map((element) => {
                return element[fieldName];
            })
            .filter(filterDistinct);
        return colArray.map((element) => {
            const item = {};
            item[fieldName] = element;
            return {
                ...arr.find((el) => el[fieldName] === element),
                ...item,
            };
        });
    }
    return [];
};

export { filterDistinct, distinctArrayOn };
