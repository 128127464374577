/** @format */
import { intersection } from "lodash";
import moment from "moment";

import { userRole, specificSubject } from "src/app/graphql/types_and_fragments";
import { ISavedQuizData, IScoreData } from "src/redux/reducer_types";
import { store } from "src/redux/store";
import { ICtUserData } from "src/app/graphql/QUserInfo";

import intl from "react-intl-universal";
import parentLogger from "src/app/AppSupport/Utils/loggerService";

const logger = parentLogger.child({
    module: "DataUtils",
});
const xtx = (str: string, key?: string) => intl.get(key || str || "x") || str;

export const prepareUserData = (user: any) => {
    const savedUser = {
        attributes: {
            "custom:manual_sub": user.attributes["custom:manual_sub"],
            email: user.attributes.email,
            email_verified: user.attributes.email_verified,
            "custom:role": user.attributes["custom:role"],
            // family_name: user.attributes.family_name,
            // given_name: user.attributes.given_name,
            phone_number: user.attributes.phone_number,
            phone_number_verified: user.attributes.phone_number_verified,
            // sub: user.attributes.sub,
        },
        cognitoSub: user.attributes["custom:manual_sub"],
        signInUserSession: {
            idToken: user.signInUserSession.idToken,
        },
    };

    return savedUser;
};

const getSpecificSubjectCurriculumMap = (
    ss: specificSubject.ISpecificSubject
) => {
    // N.B. creation of map is unnecessary since the dropdownOptions object is being created
    // so it can be saved in Redux.  Can be removed if slow
    const ces = (ss.currElements && ss.currElements.nodes) || [];

    // OLD - prior to change in curriculum element design
    // const cesTopLevel =
    //     ces.length > 0 && ces[0].elementParentId === 0
    //         ? ces[0].id
    //         : undefined;

    const dropdownOptions = ces
        // .filter((el) => el.id !== cesTopLevel)
        .reduce<{ key: string; text: string }[]>((dopts, el) => {
            if (el.id === ss.startingCe) {
                dopts.push({
                    key: `H${el.id}`,
                    text: el.elementText,
                    // itemType: DropdownMenuItemType.Header,
                });
            }
            dopts.push({ key: el.id.toString(), text: el.elementText });
            return dopts;
            // eslint-disable-next-line
        }, []);

    return dropdownOptions;
};

export const prepareDbUserData = (data: ICtUserData, handleSs: boolean) => {
    logger.debug(
        `in prepareDbUserData: got relevant data of: mobileAppRegistered: ${data.mobileAppRegistered}, cognitoSub: ${data.cognitoSub}, numRoles: ${data.ctUserRoles.nodes.length}`
    );
    const { nodeId, id, cognitoSub, mobileAppRegistered } = data;
    const {
        email,
        firstName,
        lastName,
        authorised,
        accessIds,
        mobile,
        pgRole,
        userPreferences,
        additionalInfo,
    } = data.userConfidentialById;
    const roles: userRole.IUserRole[] = data.ctUserRoles.nodes;
    let roleData: any[] = [];
    const specificSubjectInfoArray: any[] = [];
    if (roles.length > 0) {
        roleData = roles.map((role) => augmentRole(role));

        if (handleSs) {
            roles
                .filter((r) => !r.expired)
                .forEach((role) => {
                    const ss = role.specificSubjectBySpecificSubjectId;
                    const dropdownOptions = getSpecificSubjectCurriculumMap(ss);
                    const newSpecificSubject = {
                        ...ss,
                        dropdownOptions,
                    };
                    specificSubjectInfoArray.push(newSpecificSubject);
                });
        }
    }
    const lastRoles = roles.filter(
        (r) => r.userRoleConfidentialById.lastUserRole
    );
    logger.debug(`mobileAppRegistered (DataUtils) is "${mobileAppRegistered}"`);
    return {
        dbUser: {
            cognitoSub,
            email,
            firstName,
            lastName,
            id,
            nodeId,
            authorised,
            accessIds,
            mobile,
            pgRole,
            userPreferences,
            additionalInfo,
            mobileAppRegistered,
        },
        roles: roleData,
        lastRoleId: lastRoles.length > 0 ? lastRoles[0].id : undefined,
        specificSubjectInfoArray,
    };
};

export const augmentRole = (role) => {
    const studentGroupMembers: userRole.IStudentGroupMember[] =
        role.myStudents.nodes.map((s) => {
            return {
                studentNodeId: s.nodeId,
                studentId: s.id,
                studentName: s.publicName,
            };
        });

    return {
        ...role,
        studentGroupMembers,
    };
};

export const getDisplayedUserRoleId = (
    activeRole: userRole.IUserRole,
    currentStudent: userRole.IStudentGroupMember | null
): number => {
    let displayedUserRoleId: number = activeRole.id;
    switch (activeRole.userRoleConfidentialById.userRole.toLowerCase()) {
        case "student":
            break;

        case "tutor":
        case "teacher":
        case "parent":
        case "admin":
            displayedUserRoleId =
                (currentStudent && currentStudent.studentId) || -1;
            break;
    }
    return displayedUserRoleId;
};

// interface IScoreData {
//     questionId: number;
//     score?: number;
//     answer?: string;
//     reversed: boolean;
//     markedAsTooDifficult: boolean;
//     markedAsTooDifficultWhy?: string;
// }

export const prepareScoresData = (
    savedQuizData: ISavedQuizData,
    quizId: number,
    selfAssessed: boolean
) => {
    const qd = savedQuizData[quizId.toString()];
    const scoresData: IScoreData[] = [];
    Object.keys(qd).forEach((k) => {
        if (
            (qd[k].answered && (qd[k].score !== 0 || !selfAssessed)) ||
            qd[k].markedAsTooDifficult
        ) {
            scoresData.push({
                questionId: qd[k].questionId,
                score: qd[k].score === undefined ? 0 : qd[k].score! + 1 || 0, // gradientSlider goes from 0 to 6, scores should be from 1 to 7
                answered: qd[k].answered,
                answer: qd[k].answer,
                reversed: qd[k].reversed,
                markedAsTooDifficult: qd[k].markedAsTooDifficult || false,
                markedAsTooDifficultWhy:
                    qd[k].markedAsTooDifficultWhy || undefined,
            });
        }
    });
    return scoresData;
};

export const getAssignmentProgressData = (
    savedQuizData: ISavedQuizData,
    quizId: number
) => {
    const qd = savedQuizData[quizId.toString()];
    return qd;
};

export const checkCanWrite = (fullWriteList: string[]) => {
    // @ts-ignore
    const accessIds = store.getState().app.dbUser.accessIds;
    if (accessIds) {
        const arrayIntersect = intersection(fullWriteList, accessIds);
        return arrayIntersect.length > 0;
    }
    return false;
};

const transformBigint = (bigintString: string) => {
    return moment(parseInt(bigintString, 10)).format("ll");
};

const functionInputStringMap = {
    vFirstName: ["first name", undefined],
    vLastName: ["last name", undefined],
    vUserPreferences: ["user preferences", undefined],
    vAdditionalInfoUser: ["additional user info", undefined],
    vPublicName: ["public role name", undefined],
    vRoleStartDate: ["role starting date", transformBigint],
    vRoleEndDate: ["role ending date", transformBigint],
    vRolePreferences: ["role preferences", undefined],
    vAdditionalInfoRole: ["role additional info", undefined],
    vRoleDescription: ["role description", undefined],
};

export const getNameFromFunctionInput = (functionInput: string) => {
    return xtx(
        functionInputStringMap[functionInput][0],
        `data_utils.${functionInput}`
    );
};

export const getStringFromValue = (functionInput, val) => {
    let newVal = val;
    if (functionInputStringMap[functionInput][1]) {
        newVal = functionInputStringMap[functionInput][1](val);
    }
    if (typeof newVal === "object") {
        return JSON.stringify(newVal);
    } else {
        return newVal;
    }
};
