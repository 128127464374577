/** @format */

import gql from "graphql-tag";

export const SET_LATEST_ROLE = gql`
    mutation slr($input: SetLatestRoleInput!) {
        setLatestRole(input: $input) {
            ctUserRole {
                nodeId
                id
                userRoleConfidentialById {
                    id
                    nodeId
                    rolePreferences
                }
            }
        }
    }
`;

export interface IMSetLatestRoleResult {
    setLatestRole: {
        ctUserRole: {
            nodeId: string;
            id: number;
            userRoleConfidentialById: {
                id: number;
                nodeId: string;
                rolePreferences: {
                    [key: string]: any;
                };
            };
        };
    };
}

export interface IMSetLatestRoleVars {
    input: {
        vRoleId: number;
    };
}
