import ReduxConstants from "../ReduxConstants";

const { Account } = ReduxConstants;

const defaultExport = (dispatch) => {
    return {
        setRegistrationDetails: (data) => {
            dispatch({
                type: Account.SetRegistrationDetails,
                payload: data,
            });
        },
        setLoginDetails: (data) => {
            dispatch({
                type: Account.SetLoginDetails,
                payload: data,
            });
        },
    };
};

export default defaultExport;
