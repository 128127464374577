/** @format */

import { ISpecificSubject } from "./specificSubject"; // specificSubjectFullFields
import { IClassGrouping } from "./classGrouping";

// TODO: consider later for collecting the required fragments
// const moduleSpecifier = "./utils.mjs";
// import(moduleSpecifier).then((module) => {
//     module.default();
//     // → logs 'Hi from the default export!'
//     module.doStuff();
//     // → logs 'Doing stuff…'
// });

// requires
// ${specificSubject.specificSubjectFullFields}
// ${classGrouping.classGroupingFields}
// ${subjectArea.subjectAreaBasicFields}
// ${textLanguage.textLanguageFields}
// ${curriculumElement.ceFullFields}
// ${roleSetupPreset.roleSetupPresetBasicFields}
export const privateRoleFields = `
fragment privateRoleFields on CtUserRole {
    nodeId
    id
    publicName
    expired
    classGroupingId
    ctUserId
    userRoleConfidentialById {
        id
        nodeId
        roleStartDate
        roleEndDate
        rolePreferences
        roleSpecificEmail
        roleDescription
        userRole
        additionalInfo
        lastUserRole
    }
    myStudents {
        nodes {
            nodeId
            id
            publicName
            expired
        }
    }
    studentsInClass {
        nodes {
            nodeId
            id
            publicName
            expired
        }
    }
    specificSubjectBySpecificSubjectId {
        ...specificSubjectFullFields
    }
    classGroupingById: classGroupingByClassGroupingId {
        ...classGroupingFields
    }
}
`;

export type userRole = "STUDENT" | "PARENT" | "TEACHER" | "TUTOR" | "ADMIN";

export interface IUserRole {
    nodeId: string;
    id: number;
    publicName: string;
    expired: boolean;
    classGroupingId: number;
    ctUserId: number;
    userRoleConfidentialById: {
        id: number;
        nodeId: string;
        roleDescription: string;
        roleSpecificEmail: string;
        roleStartDate: string;
        roleEndDate: string;
        rolePreferences: {
            [key: string]: any;
        };
        userRole: userRole;
        additionalInfo: string; // jsonb
        lastUserRole: boolean;
    };
    myStudents: {
        nodes: {
            nodeId: string;
            id: number;
            publicName: string;
            expired: boolean;
        }[];
    };
    studentsInClass: {
        nodes: {
            nodeId: string;
            id: number;
            publicName: string;
            expired: boolean;
        }[];
    };
    specificSubjectBySpecificSubjectId: ISpecificSubject;
    classGroupingById: IClassGrouping;
}

export interface IActiveUserRole extends IUserRole {
    questionList: number[];
    hasWriteAccess: boolean;
}

export interface IStudentGroupMember {
    studentClientId?: number;
    studentNodeId: string;
    studentId: number;
    studentName: string;
    studentDesc?: string;
    studentSpecificSubject?: ISpecificSubject;
}
