/** @format */

import * as fromActions from "./app_actions";
import { IStoreAuth } from "./reducer_types";

// @ts-ignore -- problem is the type of initial state since it is partial
export const authReducer = (
    state: IStoreAuth = {},
    action: fromActions.ActionsType
) => {
    switch (action.type) {
        case fromActions.ActionTypes.SAVE_AUTH_PROPS: {
            const { authProps } = action.payload;
            return { ...state, authProps };
        }

        default:
            return state;
    }
};
