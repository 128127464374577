/** @format */

import gql from "graphql-tag";

import { graphql_types, quiz } from "./types_and_fragments";

export const NON_STUDENT_RETRIEVE_RELEVANT_QUIZZES = gql(`
query filteredNonStudentRelevantQuizzes(
    $quizzesPerPageRequest: Int!
    $cursor: Cursor
    $vRoleId: Int!
    $vStudentRoleIds: [Int!]
    $vAutoCreated: Boolean
    $vMaxDifficulty: Float
    $vRequiredTags: [Int!]
    $vPartialQuizName: String
    $vOverdueBy: BigInt
    $vRequiredCes: [Int!]
    $vHideAutoQuizzesIfCompleted: Boolean!
    $vMaxAsofdate: BigInt
    $vMinAsofdate: BigInt
    $vMinDifficulty: Float
    $vMaxQuestions: Int
    $vMinQuestions: Int
    ) {
    quizzes: nonStudentRetrieveRelevantQuizzes(
        first: $quizzesPerPageRequest
        after: $cursor
        vRoleId: $vRoleId
        vStudentRoleIds: $vStudentRoleIds,    
        vAutoCreated: $vAutoCreated
        vMaxDifficulty: $vMaxDifficulty
        vRequiredTags: $vRequiredTags
        vPartialQuizName: $vPartialQuizName
        vOverdueBy: $vOverdueBy
        vRequiredCes: $vRequiredCes
        vHideAutoQuizzesIfCompleted: $vHideAutoQuizzesIfCompleted
        vMaxAsofdate: $vMaxAsofdate
        vMinAsofdate: $vMinAsofdate
        vMinDifficulty: $vMinDifficulty
        vMaxQuestions: $vMaxQuestions
        vMinQuestions: $vMinQuestions
        ) {
        nodes {
            ...extendedQuizWithAutoFields  
        }
        pageInfo {
            endCursor
            hasNextPage
        }
    }
}
${quiz.extendedQuizWithAutoFields}
${quiz.extendedQuizFields}
${quiz.basicQuizFields}
`);

export interface INonStudentRelevantQuizzesData {
    quizzes: {
        nodes: quiz.IQuizExtendedWithAuto[];
        pageInfo: graphql_types.IPageForwardInfo;
    };
}

export interface INonStudentRelevantQuizzesVariables {
    quizzesPerPageRequest: number;
    cursor: string | null;
    vRoleId: number;
    vStudentRoleIds: number[];
    vAutoCreated?: boolean;
    vMaxDifficulty?: number;
    vRequiredTags?: number[];
    vPartialQuizName?: string;
    vOverdueBy?: string; // BigInt
    vRequiredCes?: number[];
    vHideAutoQuizzesIfCompleted: boolean;
    vMaxAsofdate?: string; // BigInt
    vMinAsofdate?: string; // BigInt
    vMinDifficulty?: number;
    vMaxQuestions?: number;
    vMinQuestions?: number;
}
