import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import WebfontLoader from "@dr-kobros/react-webfont-loader";
import * as Sentry from "@sentry/browser";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// import { BrowserRouter } from "react-router-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import Routes from "./Routes";
import { store } from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import AmplifyBridge from "./components/auth/AmplifyBridge";
import { HistoryWrapper } from "src/app/Configuration/BrowserHistory";

import "normalize.css/normalize.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "pure-react-carousel/dist/react-carousel.es.css";

import "sticky-react-table/lib/themes/light.scss";

// import "./app/THEOplayerWrapper.css";

import "./Resources/GlobalStyles.scss"; // TODO - see about where this should be applied, and in what order (last?)
import "src/app/AppSupport/bp4-css-overrides.css";
import "./index.css";

// import "./ReactotronConfig";

new AmplifyBridge(store);

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
});

const config = {
    typekit: {
        id: "dse1zon",
    },
};

const history = createBrowserHistory();
HistoryWrapper.init(history);

ReactDOM.render(
    <CookiesProvider>
        <Provider store={store}>
            <WebfontLoader config={config}>
                <Router history={history}>
                    <Routes />
                    <Zendesk
                        zendeskKey={
                            process.env.REACT_APP_ZENDESK_KEY ||
                            "no_key_provided"
                        }
                        onLoaded={() => {
                            // console.log("Zendesk is loaded");
                            ZendeskAPI("webWidget", "hide");
                        }}
                    />
                </Router>
            </WebfontLoader>
        </Provider>
    </CookiesProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register(serviceWorkerConfig);
serviceWorker.unregister();
