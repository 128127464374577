import React, { useContext, useState, Fragment } from "react";
import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { Enums } from "../Resources";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { linkActions } from "../Actions";
import intl from "react-intl-universal";
import { Utils } from "../app/AppSupport";

const xtx = (str: string, key?: string) => intl.get(key || str || "x") || str;

declare type DialogButtonTypes = "close-only" | "submit-cancel" | "yes-no";

type IPageContext = {
    showDialog: (
        title: string,
        contentComponent: React.FunctionComponent,
        buttonsType?: DialogButtonTypes,
        onCancel?: Function,
        onConfirm?: Function
    ) => void;
    showToast: (
        message: React.ReactNode,
        timeout: number,
        intent: Intent,
        action?: any
    ) => void;
};

const PageContext = React.createContext<Partial<IPageContext>>({});

export function usePage() {
    const { showDialog, showToast } = useContext(PageContext);

    return {
        showDialog,
        showToast,
    };
}

const PageHandler = (props) => {
    const actions = linkActions(useDispatch());

    // @ts-ignore
    const { dialog } = useSelector((state) => state.general, shallowEqual);

    const { ContentComponent } = dialog;

    const [confirmButtonEnabled, updateConfirmButtonEnabled] = useState(true);

    const showDialog = (
        title,
        contentComponent,
        buttonsType = Enums.DialogButtonTypes.CloseOnly,
        onCancel,
        onConfirm
    ) => {
        actions.showDialog(
            title,
            contentComponent,
            buttonsType,
            onCancel,
            onConfirm
        );
    };

    const setDialogConfirmButtonEnabled = (isEnabled) => {
        updateConfirmButtonEnabled(isEnabled);
    };

    const handleDialogCancel = () => {
        dialog.onCancel();
        actions.hideDialog();
    };

    const handleDialogConfirm = () => {
        dialog.onConfirm();
        actions.hideDialog();
    };

    const renderButtons = () => {
        const result: React.ReactElement[] = [];
        switch (dialog.buttonsType) {
            case Enums.DialogButtonTypes.CloseOnly:
                result.push(
                    <Button key="1" onClick={handleDialogCancel}>
                        {xtx("Dismiss", "dc.dismiss")}
                    </Button>
                );
                break;
            case Enums.DialogButtonTypes.SubmitCancel:
                result.push(
                    <Button key="1" onClick={handleDialogCancel}>
                        {xtx("Cancel", "dc.cancel")}
                    </Button>
                );
                result.push(
                    <Button
                        key="2"
                        onClick={handleDialogConfirm}
                        intent={Intent.PRIMARY}
                        disabled={!confirmButtonEnabled}
                    >
                        {xtx("Submit", "dc.submit")}
                    </Button>
                );
                break;
            case Enums.DialogButtonTypes.YesNo:
                result.push(
                    <Button key="1" onClick={handleDialogCancel}>
                        {xtx("No", "dc.no")}
                    </Button>
                );
                result.push(
                    <Button
                        key="2"
                        onClick={handleDialogConfirm}
                        intent={Intent.PRIMARY}
                        disabled={!confirmButtonEnabled}
                    >
                        {xtx("Yes", "dc.yes")}
                    </Button>
                );
                break;
            default:
                break;
        }
        return result;
    };

    return (
        <PageContext.Provider
            value={{
                showDialog,
                showToast: Utils.showToast,
            }}
        >
            <Fragment>
                {props.children}
                <Dialog
                    onClose={handleDialogCancel}
                    isOpen={dialog.isOpen}
                    title={dialog.title}
                    autoFocus
                    canEscapeKeyClose
                    canOutsideClickClose
                    enforceFocus
                >
                    <div className={Classes.DIALOG_BODY}>
                        {ContentComponent && (
                            <ContentComponent
                                setSubmitButtonEnabled={(newStatus) =>
                                    setDialogConfirmButtonEnabled(newStatus)
                                }
                            />
                        )}
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            {renderButtons()}
                        </div>
                    </div>
                </Dialog>
            </Fragment>
        </PageContext.Provider>
    );
};

export default PageHandler;
