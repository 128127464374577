import { ItemProps } from "src/design-components/AppSelect";

const DialogButtonTypes = {
    CloseOnly: "close-only",
    SubmitCancel: "submit-cancel",
    YesNo: "yes-no",
};

export { DialogButtonTypes };

export const questionTypeItems: ItemProps[] = [
    {
        key: "Concept",
        label: "Concept",
    },
    {
        key: "Fact",
        label: "Definition",
    },
    {
        key: "Computation",
        label: "Exercise",
    },
];

export type QuestionTypes = "Concept" | "Definition" | "Exercise";

export const difficultyItems: ItemProps[] = [
    {
        key: "1",
        label: "Level 1: Easy",
        additionalData: {
            level: 1,
            text: "Easy",
        },
    },
    {
        key: "2",
        label: "Level 2",
        additionalData: {
            level: 1,
        },
    },
    {
        key: "3",
        label: "Level 3: Moderate",
        additionalData: {
            level: 1,
            text: "Moderate",
        },
    },
    {
        key: "4",
        label: "Level 4",
        additionalData: {
            level: 1,
        },
    },
    {
        key: "5",
        label: "Level 5: Challenging",
        additionalData: {
            level: 1,
            text: "Challenging",
        },
    },
    {
        key: "6",
        label: "Level 6",
        additionalData: {
            level: 1,
        },
    },
    {
        key: "7",
        label: "Level 7: Difficult",
        additionalData: {
            level: 7,
            text: "Difficult",
        },
    },
];

export const scoreToColorArray = [
    "bg-red-400", // 1
    "bg-red-200", // 2
    "bg-orange-400", // 3
    "bg-amber-200", // 4
    "bg-cyan-300", // 5
    "bg-teal-200", // 6
    "bg-emerald-600", // 7
];

export const scoreToTextColorArray = [
    "text-stone-50", // 1
    "text-neutral-900", // 2
    "text-neutral-900", // 3
    "text-neutral-900", // 4
    "text-neutral-900", // 5
    "text-neutral-900", // 6
    "text-stone-50", // 7
];
