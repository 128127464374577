/** @format */

import { IRoleSetupPresetFull } from "./roleSetupPreset";

export const invitationBasicFields = `
fragment invitationBasicFields on Invitation {
    id
    nodeId
    invitationUuid
    invitedEmail
    invitedFirstName
    invitedLastName
    isOpen
    invitedUser
    invitationData
    asofdate
    invitationExpiry
    accepted
    roleSetupPreset: roleSetupPresetByRoleSetupPresetId {
        ...roleSetupPresetFullFields
    }
    invitedBy: ctUserRoleByInvitedByRole {
        publicName
    }
}
`;

// requires invitationBasicFields
export const invitationWithClassFields = `
fragment invitationWithClassFields on Invitation {
    ...invitationBasicFields
    class {
        id
        nodeId
        className
        classShortDesc
        classLongDesc
        classStart
        classEnd
        clientName
        ss: specificSubjectBySpecificSubjectId {
            id
            nodeId
            specificSubjectName
        }
    }
}
`;

export interface IInvitationBasic {
    id: number;
    nodeId: string;
    invitationUuid: string;
    invitedEmail: string;
    invitedFirstName: string;
    invitedLastName: string;
    isOpen: boolean;
    invitedUser: number;
    invitationData: any;
    asofdate: string; // bigint
    invitationExpiry: string; // is bigint
    accepted: boolean;
    roleSetupPreset: IRoleSetupPresetFull;
    invitedBy: {
        publicName: string;
    };
}

export interface IInvitationWithClass extends IInvitationBasic {
    class: {
        id: number;
        nodeId: string;
        className: string;
        classShortDesc: string;
        classLongDesc: string;
        classStart: string; // bigint
        classEnd: string; // bigint
        clientName: string;
        ss: {
            id: number;
            nodeId: string;
            specificSubjectName: string;
        };
    };
}
