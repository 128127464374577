import gql from "graphql-tag";

export const CAPTURE_HELP_VIDEO_EVENT = gql(`
mutation chve($input: CaptureHelpVideoEventInput!) {
    captureHelpVideoEvent(input: $input) {
        clientMutationId
    }
}
`);

export interface ICaptureHelpVideoEventInput {
    input: {
        vUserId: number;
        vHelpVideoId: number;
        vStartEndData: any; // JSONB
    };
}
