/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import intl from "react-intl-universal";
import { find } from "lodash";

import asyncComponent from "src/app/AsyncComponent";
import { ResponsiveHandler, PageHandler } from "./Handlers";
import AppWithAuth from "./components/AppWithAuth";

import { Actions } from "src/redux/app_actions";
import { Languages } from "src/Resources";
import { IStore } from "./redux/reducer_types";

const AsyncValidateWithToken = asyncComponent(() =>
    import("src/components/admin/ValidateWithToken")
);
const AsyncSignUpSuccess = asyncComponent(() =>
    import("src/components/admin/SignUp/Step02")
);
const AsyncLegalPage = asyncComponent(() =>
    import("src/components/admin/LegalPage")
);

const Routes = () => {
    // console.log("rendering Routes");
    const language = useSelector((state: IStore) => state.app.language);
    const dispatch = useDispatch();

    const setInitialLanguage = () => {
        let localeToUse = intl.determineLocale({
            urlLocaleKey: "lang",
            cookieLocaleKey: "lang",
        });

        let foundLanguageEntry = find(Languages.allLanguages, function (o) {
            return o.lang.indexOf(localeToUse) !== -1;
        });
        if (foundLanguageEntry) {
            localeToUse = foundLanguageEntry.lang[0];
        } else {
            localeToUse = Languages.defaultLangKey;
        }
        const variables = { language: localeToUse };
        dispatch(Actions.requestNewLanguage({ ...variables }));
    };

    useEffect(() => {
        setInitialLanguage();
    }, []);

    if (!language) {
        return <div>Loading language file...</div>;
    }

    // N.B. all routes other than token validation go through AppWithAuth

    return (
        <PageHandler>
            <ResponsiveHandler>
                <Switch>
                    <Route
                        path="/validate"
                        component={AsyncValidateWithToken}
                    />
                    <Route
                        path="/signupsucccess"
                        component={AsyncSignUpSuccess}
                    />
                    <Route
                        path="/site/:lang?/:urlEmail?"
                        component={AppWithAuth}
                    />
                    <Route
                        path={`/legal/:side/:pageName`}
                        component={AsyncLegalPage}
                    />

                    <Route component={AppWithAuth} />
                </Switch>
            </ResponsiveHandler>
        </PageHandler>
    );
};
export default Routes;
