/** @format */

import { Actions as AuthActions } from "../redux_auth";

import * as rt from "./reducer_types";
import { createAction } from "./action_helpers";

export enum ActionTypes {
    SAVE_USER_DATA = "SAVE_USER_DATA",
    SAVE_ROLES_DATA = "SAVE_ROLES_DATA",
    SAVE_ONE_ROLE = "SAVE_ONE_ROLE",
    CHOOSE_ROLE = "CHOOSE_ROLE",
    CHOOSE_STUDENT = "CHOOSE_STUDENT",
    SAVE_RELEVANT_TAG_LIST = "SAVE_RELEVANT_TAG_LIST",
    SAVE_RELEVANT_CURRICULUM_OPTIONS = "SAVE_RELEVANT_CURRICULUM_OPTIONS",
    UPDATE_QALL_QUIZZES_VARIABLES = "UPDATE_QALL_QUIZZES_VARIABLES",
    SAVE_QUIZZES_SHOW_FILTER_STATE = "SAVE_QUIZZES_SHOW_FILTER_STATE",
    // SAVE_QUIZ_FILTERED_QUESTIONS_VARIABLES = "SAVE_QUIZ_FILTERED_QUESTIONS_VARIABLES",
    SAVE_GROUP_UUID_LIST = "SAVE_GROUP_UUID_LIST",
    SAVE_GRADE_LEVEL_ENUMS = "SAVE_GRADE_LEVEL_ENUMS",
    SAVE_GROUP_SHARE_TYPES = "SAVE_GROUP_SHARE_TYPES",
    SAVE_SPECIFIC_SUBJECT_INFO = "SAVE_SPECIFIC_SUBJECT_INFO",
    EPIC_COMPLETED = "EPIC_COMPLETED",
    UPLOAD_PROTECTED_FILE = "UPLOAD_PROTECTED_FILE",
    EPIC_ERROR = "EPIC_ERROR",
    EPIC_SENTRY_MESSAGE = "EPIC_SENTRY_MESSAGE",
    SAVE_IDENTITY_ID = "SAVE_IDENTITY_ID",
    SAVE_COOKIE_APPROVAL = "SAVE_COOKIE_APPROVAL",
    TOKENS_UPDATED = "TOKENS_UPDATED",
    TOKEN_UPDATE_FAILURE = "TOKEN_UPDATE_FAILURE",
    REFRESH_TOKEN_WITH_GQL = "REFRESH_TOKEN_WITH_GQL",
    GET_SUBJECT_AREA = "GET_SUBJECT_AREA",
    SAVE_SUBJECT_AREA = "SAVE_SUBJECT_AREA",
    REQUEST_NEW_LANGUAGE = "REQUEST_NEW_LANGUAGE",
    SET_ACTIVE_LANGUAGE = "SET_ACTIVE_LANGUAGE",
    SAVE_ROLE_LANGUAGE_PREFERENCE = "SAVE_ROLE_LANGUAGE_PREFERENCE",
    GET_IP_DATA = "GET_IP_DATA",
    SAVE_IP_DATA = "SAVE_IP_DATA",
    SIGN_UP = "SIGN_UP",
    SAVE_ROLE_PREFERENCES = "SAVE_ROLE_PREFERENCES",
    CAPTURE_APP_ROLE_EVENT = "CAPTURE_APP_ROLE_EVENT",
    SAVE_SIGNUP_RESPONSE = "SAVE_SIGNUP_RESPONSE",
    SAVE_AUTH_STATUS = "SAVE_AUTH_STATUS",
    SET_I18N_DONE = "SET_I18N_DONE",
    SAVE_SIGN_UP_PARAMS = "SAVE_SIGN_UP_PARAMS",
    SAVE_RAW_COGNITO_USER = "SAVE_RAW_COGNITO_USER",
    UPDATE_SAVED_QUIZ_DATA = "UPDATE_SAVED_QUIZ_DATA",
    CLEAR_SAVED_QUIZ_DATA = "CLEAR_SAVED_QUIZ_DATA",
    GRADE_QUIZ = "GRADE_QUIZ",
    PERSIST_QUIZ_PROGRESS = "PERSIST_QUIZ_PROGRESS",
    REQUEST_FILTERED_QUESTIONS = "REQUEST_FILTERED_QUESTIONS",
    SAVE_FILTERED_QUESTIONS = "SAVE_FILTERED_QUESTIONS",
    // VALIDATE_WITH_TOKEN = "VALIDATE_WITH_TOKEN",
    SAVE_INVITATIONS = "SAVE_INVITATIONS",
    REQUEST_SHARING_SETUP = "REQUEST_SHARING_SETUP",
    STOP_QUIZZES = "STOP_QUIZZES",
    CREATE_ROLE = "CREATE_ROLE",
    REFRESH_ROLES = "REFRESH_ROLES",
    RESPOND_TO_INVITATION = "RESPOND_TO_INVITATION",
    SHOW_TOAST = "SHOW_TOAST",
    UPDATE_USER_AND_ROLE_INFO = "UPDATE_USER_AND_ROLE_INFO",
    WEBSOCKET_TRY_CONNECT = "WEBSOCKET_TRY_CONNECT",
    WEBSOCKET_TRY_DISCONNECT = "WEBSOCKET_TRY_DISCONNECT",
    WEBSOCKET_CONNECTED = "WEBSOCKET_CONNECTED",
    WEBSOCKET_DISCONNECTED = "WEBSOCKET_DISCONNECTED",
    WEBSOCKET_ERROR = "WEBSOCKET_ERROR",
    WEBSOCKET_MESSAGE_SEND = "WEBSOCKET_MESSAGE_SEND",
    WEBSOCKET_MESSAGE_SENT = "WEBSOCKET_MESSAGE_SENT",
    WEBSOCKET_MESSAGE_RECIEVED = "WEBSOCKET_MESSAGE_RECIEVED",
    SAVE_WS_UUID = "SAVE_WS_UUID",
    WEBSOCKET_START_TO_PING = "WEBSOCKET_START_TO_PING",
    WEBSOCKET_STOP_PING = "WEBSOCKET_STOP_PING",
    CONNECT_TO_SERVER = "CONNECT_TO_SERVER",
    MAINTAIN_WS = "MAINTAIN_WS",
    DO_NOT_MAINTAIN_WS = "DO_NOT_MAINTAIN_WS",
    FAIL_TO_FETCH = "FAIL_TO_FETCH",
    FAIL_TO_FETCH_FINAL_FAILURE = "FAIL_TO_FETCH_FINAL_FAILURE",
    SET_FAIL_TO_FETCH_COUNT = "SET_FAIL_TO_FETCH_COUNT",
    SEND_INVITATIONS = "SEND_INVITATIONS",
    ADD_TO_QUIZ_COMPLETIONS = "ADD_TO_QUIZ_COMPLETIONS",
    SET_CURRENT_COLLECTION_INFO = "SET_CURRENT_COLLECTION_INFO",
    CAPTURE_HELP_VIDEO_EVENT = "CAPTURE_HELP_VIDEO_EVENT",
    ADD_TO_MY_QUESTION_LIST = "ADD_TO_MY_QUESTION_LIST",
    REMOVE_FROM_MY_QUESTION_LIST = "REMOVE_FROM_MY_QUESTION_LIST",
    CLEAR_MY_QUESTION_LIST = "CLEAR_MY_QUESTION_LIST",
}

export const AppActions = {
    chooseRole: (payload: rt.IChooseRole) =>
        createAction(ActionTypes.CHOOSE_ROLE, payload),
    createRole: (payload: rt.ICreateRole) =>
        createAction(ActionTypes.CREATE_ROLE, payload),
    refreshRoles: (payload: {}) =>
        createAction(ActionTypes.REFRESH_ROLES, payload),
    respondToInvitation: (payload: rt.IRespondToInvitation) =>
        createAction(ActionTypes.RESPOND_TO_INVITATION, payload),
    sendInvitations: (payload: rt.ISendInvitationsPayload) =>
        createAction(ActionTypes.SEND_INVITATIONS, payload),

    chooseStudent: (payload: rt.IChooseStudent) =>
        createAction(ActionTypes.CHOOSE_STUDENT, payload),
    epicCompleted: (payload: any) =>
        createAction(ActionTypes.EPIC_COMPLETED, payload),
    showToast: (payload: rt.IShowToastPayload) =>
        createAction(ActionTypes.SHOW_TOAST, payload),
    epicError: (payload: rt.IEpicErrorPayload) =>
        createAction(ActionTypes.EPIC_ERROR, payload),
    epicSentryMessage: (payload: rt.IEpicSentryMessagePayload) =>
        createAction(ActionTypes.EPIC_SENTRY_MESSAGE, payload),
    saveGradeLevelEnums: (payload: rt.ISaveGradeLevelEnums) =>
        createAction(ActionTypes.SAVE_GRADE_LEVEL_ENUMS, payload),
    saveGroupUuidList: (payload: rt.ISaveGroupUuidList) =>
        createAction(ActionTypes.SAVE_GROUP_UUID_LIST, payload),
    saveInvitations: (payload: rt.ISaveInvitations) =>
        createAction(ActionTypes.SAVE_INVITATIONS, payload),
    saveIdentityId: (payload: rt.ISaveIdentityIdPayload) =>
        createAction(ActionTypes.SAVE_IDENTITY_ID, payload),
    // saveQuizFilteredQuestionsVariables: (
    //     payload: rt.ISaveQuizFilteredQuestions
    // ) =>
    //     createAction(
    //         ActionTypes.SAVE_QUIZ_FILTERED_QUESTIONS_VARIABLES,
    //         payload
    //     ),
    saveQuizzesShowFilterState: (payload: rt.IShowQuizzesFilter) =>
        createAction(ActionTypes.SAVE_QUIZZES_SHOW_FILTER_STATE, payload),
    saveRelevantCurriculumElements: (
        payload: rt.ISaveRelevantCurriculumElementsPayload
    ) => createAction(ActionTypes.SAVE_RELEVANT_CURRICULUM_OPTIONS, payload),
    saveRelevantTagList: (payload: rt.ISaveRelevantTagsPayload) =>
        createAction(ActionTypes.SAVE_RELEVANT_TAG_LIST, payload),
    saveRolesData: (payload: rt.ISaveRolesData) =>
        createAction(ActionTypes.SAVE_ROLES_DATA, payload),
    saveOneRole: (payload: rt.ISaveOneRole) =>
        createAction(ActionTypes.SAVE_ONE_ROLE, payload),
    saveSpecificSubjectInfo: (payload: rt.ISaveSpecificSubjectInfo) =>
        createAction(ActionTypes.SAVE_SPECIFIC_SUBJECT_INFO, payload),
    saveUserData: (payload: rt.ISaveUserPayload) =>
        createAction(ActionTypes.SAVE_USER_DATA, payload),
    updateQAllQuizzesVariables: (payload: rt.ISaveQuizFilter) =>
        createAction(ActionTypes.UPDATE_QALL_QUIZZES_VARIABLES, payload),
    uploadProtectedFile: (payload: rt.IUploadProtectedFile) =>
        createAction(ActionTypes.UPLOAD_PROTECTED_FILE, payload),
    stopQuizzes: (payload: rt.IStopQuizzes) =>
        createAction(ActionTypes.STOP_QUIZZES, payload),
    setCurrentCollectionInfo: (payload: rt.ISetCurrentCollectionInfo) =>
        createAction(ActionTypes.SET_CURRENT_COLLECTION_INFO, payload),

    gqlToRefreshToken: (payload: {}) =>
        createAction(ActionTypes.REFRESH_TOKEN_WITH_GQL, payload),
    saveCookieApproval: (payload: rt.ISaveCookieApprovalPayload) =>
        createAction(ActionTypes.SAVE_COOKIE_APPROVAL, payload),
    tokenUpdateFailure: (payload: rt.IEpicErrorPayload) =>
        createAction(ActionTypes.TOKEN_UPDATE_FAILURE, payload),
    tokensUpdated: (payload: {}) =>
        createAction(ActionTypes.TOKENS_UPDATED, payload),
    getSubjectArea: (payload: {}) =>
        createAction(ActionTypes.GET_SUBJECT_AREA, payload),
    saveSubjectArea: (payload: rt.ISaveCurrentSubjectArea) =>
        createAction(ActionTypes.SAVE_SUBJECT_AREA, payload),
    requestNewLanguage: (payload: rt.ISetCurrentLanguage) =>
        createAction(ActionTypes.REQUEST_NEW_LANGUAGE, payload),
    setActiveLanguage: (payload: rt.ISetCurrentLanguage) =>
        createAction(ActionTypes.SET_ACTIVE_LANGUAGE, payload),
    saveRoleLanguagePref: (payload: rt.ISaveRoleLanguagePref) =>
        createAction(ActionTypes.SAVE_ROLE_LANGUAGE_PREFERENCE, payload),

    setI18nDone: (payload: rt.ISetI18nDone) =>
        createAction(ActionTypes.SET_I18N_DONE, payload),
    saveSignUpParams: (payload: rt.ISaveSignUpPayload) =>
        createAction(ActionTypes.SAVE_SIGN_UP_PARAMS, payload),

    getIpData: (payload: {}) => createAction(ActionTypes.GET_IP_DATA, payload),
    saveIpData: (payload: rt.ISaveIpDataPayload) =>
        createAction(ActionTypes.SAVE_IP_DATA, payload),
    signUp: (payload: rt.ISignUpPayload) =>
        createAction(ActionTypes.SIGN_UP, payload),
    saveSignUpResponse: (payload: rt.ISaveSignUpResponse) =>
        createAction(ActionTypes.SAVE_SIGNUP_RESPONSE, payload),
    saveAuthStatus: (payload: rt.ISaveAuthStatus) =>
        createAction(ActionTypes.SAVE_AUTH_STATUS, payload),
    saveRawCognitoUser: (payload: rt.IRawCognitoUser) =>
        createAction(ActionTypes.SAVE_RAW_COGNITO_USER, payload),
    // validateWithToken: (payload: rt.IValidateWithToken) =>
    //     createAction(ActionTypes.VALIDATE_WITH_TOKEN, payload),

    saveRolePreferences: (payload: rt.ISaveRolePreferencesPayload) =>
        createAction(ActionTypes.SAVE_ROLE_PREFERENCES, payload),
    updateUserAndRoleInfo: (payload: rt.IUpdateUserAndRoleInfoPayload) =>
        createAction(ActionTypes.UPDATE_USER_AND_ROLE_INFO, payload),
    captureAppRoleEvent: (payload: rt.ICaptureAppRoleEventPayload) =>
        createAction(ActionTypes.CAPTURE_APP_ROLE_EVENT, payload),
    captureHelpVideoEvent: (payload: rt.ICaptureHelpVideoEventPayload) =>
        createAction(ActionTypes.CAPTURE_HELP_VIDEO_EVENT, payload),

    updateSavedQuizData: (payload: rt.IScoreUpdate) =>
        createAction(ActionTypes.UPDATE_SAVED_QUIZ_DATA, payload),
    clearSavedQuizData: (payload: rt.IClearSavedQuizData) =>
        createAction(ActionTypes.CLEAR_SAVED_QUIZ_DATA, payload),
    gradeQuiz: (payload: rt.IGradeQuiz) =>
        createAction(ActionTypes.GRADE_QUIZ, payload),
    persistQuizProgress: (payload: rt.IPersistQuizProgress) =>
        createAction(ActionTypes.PERSIST_QUIZ_PROGRESS, payload),
    addToQuizCompletions: (payload: rt.IAddToQuizCompletions) =>
        createAction(ActionTypes.ADD_TO_QUIZ_COMPLETIONS, payload),

    requestFilteredQuestions: (payload: rt.IRequestFilteredQuestions) =>
        createAction(ActionTypes.REQUEST_FILTERED_QUESTIONS, payload),
    saveFilteredQuestions: (payload: rt.ISaveFilteredQuestions) =>
        createAction(ActionTypes.SAVE_FILTERED_QUESTIONS, payload),

    requestSharingSetup: (payload: rt.IRequestSharingSetupPayload) =>
        createAction(ActionTypes.REQUEST_SHARING_SETUP, payload),
    wsConnect: (payload: rt.IWsConnect) =>
        createAction(ActionTypes.WEBSOCKET_TRY_CONNECT, payload),
    wsDisconnect: (payload: rt.IWsDisconnect) =>
        createAction(ActionTypes.WEBSOCKET_TRY_DISCONNECT, payload),
    wsConnected: (payload: {}) =>
        createAction(ActionTypes.WEBSOCKET_CONNECTED, payload),
    wsDisconnected: (payload: {}) =>
        createAction(ActionTypes.WEBSOCKET_DISCONNECTED, payload),
    wsError: (payload: rt.IWsError) =>
        createAction(ActionTypes.WEBSOCKET_ERROR, payload),
    wsMessageSend: (payload: rt.IWsSend) =>
        createAction(ActionTypes.WEBSOCKET_MESSAGE_SEND, payload),
    wsMessageSent: (payload: rt.IWsSend) =>
        createAction(ActionTypes.WEBSOCKET_MESSAGE_SENT, payload),
    wsMessageReceived: (payload) =>
        createAction(ActionTypes.WEBSOCKET_MESSAGE_RECIEVED, payload),
    saveWsUuid: (payload: rt.IWsConnect) =>
        createAction(ActionTypes.SAVE_WS_UUID, payload),
    wsStartToPing: (payload: {}) =>
        createAction(ActionTypes.WEBSOCKET_START_TO_PING, payload),
    wsStopPing: (payload: {}) =>
        createAction(ActionTypes.WEBSOCKET_STOP_PING, payload),
    newWsConnectToServer: (payload: rt.INewWsConnectToServer) =>
        createAction(ActionTypes.CONNECT_TO_SERVER, payload),
    wsMaintain: (payload: {}) => createAction(ActionTypes.MAINTAIN_WS, payload),
    wsDoNotMaintain: (payload: {}) =>
        createAction(ActionTypes.DO_NOT_MAINTAIN_WS, payload),

    failToFetch: (payload: {}) =>
        createAction(ActionTypes.FAIL_TO_FETCH, payload),
    failToFetchFailure: (payload: {}) =>
        createAction(ActionTypes.FAIL_TO_FETCH_FINAL_FAILURE, payload),
    setFailToFetchCount: (payload: rt.ISetFailToFetchCount) =>
        createAction(ActionTypes.SET_FAIL_TO_FETCH_COUNT, payload),

    clearQuestionList: (payload: rt.IClearQuestionListPayload) =>
        createAction(ActionTypes.CLEAR_MY_QUESTION_LIST, payload),
    addToMyQuestionList: (payload: rt.IQuestionListPayload) =>
        createAction(ActionTypes.ADD_TO_MY_QUESTION_LIST, payload),
    removeFromMyQuestionList: (payload: rt.IQuestionListPayload) =>
        createAction(ActionTypes.REMOVE_FROM_MY_QUESTION_LIST, payload),
};

export const Actions = {
    ...AppActions,
    ...AuthActions,
};

export type ActionsType = rt.ActionsUnion<typeof Actions>;
