import intl from "react-intl-universal";

const xtx = (str, key) => intl.get(key || str || "x") || str;

const getUserTypes = () => {
    return [
        { key: "teacher", label: xtx("Teacher", "signup.teacher") },
        { key: "student", label: xtx("Student", "signup.student") },
        { key: "tutor", label: xtx("Tutor", "signup.tutor") },
        {
            key: "clientadmin",
            label: xtx("Administrator", "signup.administrator"),
        },
        { key: "parent", label: xtx("Parent", "signup.parent") },
    ];
};

const getLanguages = () => {
    return [
        { key: "en-GB", label: "English (UK)" },
        { key: "en-US", label: "English (US)" },
        { key: "de", label: "Deutsch" },
    ];
};

export { getUserTypes, getLanguages };

export const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/gm;
