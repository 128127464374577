import ReduxConstants from "../ReduxConstants";
import { InitialStates } from "../app/Constants";

const { General } = ReduxConstants;

const defaultExport = (
    state = {
        dialog: InitialStates.getDefaultDialogState(),
    },
    action
) => {
    switch (action.type) {
        case General.SetDialogStatus:
            state = {
                ...state,
                dialog: action.payload,
            };
            break;
        default:
            break;
    }

    return { ...state };
};

export default defaultExport;
