/** @format */

import gql from "graphql-tag";

import { question } from "./types_and_fragments";

export interface IFilteredQuestionsData {
    pageInfo: {
        hasNextPage: boolean;
        endCursor: string;
    };
    nodes: question.IQuestionWithCE[];
}

export interface IFilteredQuestions {
    filteredQuestionsData: IFilteredQuestionsData;
}

// TODO: fix the concept of curriculum elements when the search if by subject area
// currently have added vSpecificSubject since that is required for question with CE

export const FILTER_QUESTIONS_BY_SUBJECT_AREA = gql(`
query q2(
    $questionsPerPageRequest: Int!
    $cursor: Cursor
    $vSubjectAreaId: Int!
    $vTextSearch: String
    $vCurriculumIds: [Int!]
    $vTagIds: [Int!]
    $vQuestionCats: [QuestionCat!]
    $vQueryLanguage: String
    $vSortBy: String
    $vLimit: Int
    $vSpecificSubjectId: Int!
) {
    filteredQuestionsData: searchQuestionsSubjectArea(
        first: $questionsPerPageRequest
        after: $cursor
        vSubjectAreaId: $vSubjectAreaId
        vTextSearch: $vTextSearch
        vCurriculumIds: $vCurriculumIds
        vTagIds: $vTagIds
        vQuestionCats: $vQuestionCats
        vQueryLanguage: $vQueryLanguage
        vSortBy: $vSortBy
        vLimit: $vLimit
        ) {
        pageInfo {
            hasNextPage
            endCursor
        }
        nodes {
            ...questionFieldsWithCE
        }
    }
}
${question.questionFieldsWithCE}
${question.questionFields}
${question.coreQuestionFields}
`);

export interface IGetQuestionsBySubjectAreaVars {
    questionsPerPageRequest: number;
    cursor: string | null;
    vSubjectAreaId: number;
    vTextSearch?: string;
    vCurriculumIds?: number[];
    vTagIds?: number[];
    vQuestionCats?: string[];
    vQueryLanguage?:
        | "danish"
        | "dutch"
        | "english"
        | "finnish"
        | "french"
        | "german"
        | "hungarian"
        | "italian"
        | "norwegian"
        | "portuguese"
        | "romanian"
        | "russian"
        | "simple"
        | "spanish"
        | "swedish"
        | "turkish";
    vSortBy?: string;
    vLimit?: number;
    vSpecificSubjectId: number;
}

export const FILTER_QUESTIONS_BY_SPECIFIC_SUBJECT = gql(`
query q3(
    $questionsPerPageRequest: Int!
    $cursor: Cursor
    $vSpecificSubjectId: Int!
    $vTextSearch: String
    $vCurriculumIds: [Int!]
    $vTagIds: [Int!]
    $vQuestionCats: [QuestionCat!]
    $vQueryLanguage: String
    $vSortBy: String
    $vLimit: Int
) {
    filteredQuestionsData: searchQuestionsSpecificSubject(
        first: $questionsPerPageRequest
        after: $cursor
        vSpecificSubjectId: $vSpecificSubjectId
        vTextSearch: $vTextSearch
        vCurriculumIds: $vCurriculumIds
        vTagIds: $vTagIds
        vQuestionCats: $vQuestionCats
        vQueryLanguage: $vQueryLanguage
        vSortBy: $vSortBy
        vLimit: $vLimit
        ) {
        pageInfo {
            hasNextPage
            endCursor
        }
        nodes {
            ...questionFieldsWithCE
        }
    }
}
${question.questionFieldsWithCE}
${question.questionFields}
${question.coreQuestionFields}
`);

export interface IGetQuestionsBySpecificSubjectVars {
    questionsPerPageRequest: number;
    cursor: string | null;
    vSpecificSubjectId: number;
    vTextSearch?: string;
    vCurriculumIds?: number[];
    vTagIds?: number[];
    vQuestionCats?: string[];
    vQueryLanguage?:
        | "danish"
        | "dutch"
        | "english"
        | "finnish"
        | "french"
        | "german"
        | "hungarian"
        | "italian"
        | "norwegian"
        | "portuguese"
        | "romanian"
        | "russian"
        | "simple"
        | "spanish"
        | "swedish"
        | "turkish";
    vSortBy?: string;
    vLimit?: number;
}

export const FILTER_QUESTIONS_FROM_CONTENT_COLLECTION = gql(`
query q_for_cc(
    $questionsPerPageRequest: Int!
    $cursor: Cursor
    $vContentCollectionId: Int!
    $vTextSearch: String
    $vCurriculumIds: [Int!]
    $vTagIds: [Int!]
    $vQuestionCats: [QuestionCat!]
    $vQueryLanguage: String
    $vSortBy: String
    $vLimit: Int
    $vSpecificSubjectId: Int!
) {
    filteredQuestionsData: filteredQuestionsFromCollection(
        first: $questionsPerPageRequest
        after: $cursor
        vContentCollectionId: $vContentCollectionId
        vTextSearch: $vTextSearch
        vCurriculumIds: $vCurriculumIds
        vTagIds: $vTagIds
        vQuestionCats: $vQuestionCats
        vQueryLanguage: $vQueryLanguage
        vSortBy: $vSortBy
        vLimit: $vLimit
        ) {
        pageInfo {
            hasNextPage
            endCursor
        }
        nodes {
            ...questionFieldsWithCE
        }
    }
}
${question.questionFieldsWithCE}
${question.questionFields}
${question.coreQuestionFields}
`);

export interface IFilterFromContentCollection {
    questionsPerPageRequest: number;
    cursor: string | null;
    vContentCollectionId: number;
    vTextSearch?: string;
    vCurriculumIds?: number[];
    vTagIds?: number[];
    vQuestionCats?: string[];
    vQueryLanguage?:
        | "danish"
        | "dutch"
        | "english"
        | "finnish"
        | "french"
        | "german"
        | "hungarian"
        | "italian"
        | "norwegian"
        | "portuguese"
        | "romanian"
        | "russian"
        | "simple"
        | "spanish"
        | "swedish"
        | "turkish";
    vSortBy?: string;
    vLimit?: number;
    vSpecificSubjectId: number;
}

