import { combineEpics } from "redux-observable";

import coreEpics from "./CoreEpics";
import adminEpics from "./AdminEpics";
import assignmentEpics from "./AssignmentEpics";
import questionEpics from "./QuestionEpics";
import websocketEpics from "./WebsocketEpics";

export const rootEpic = combineEpics(
    ...coreEpics,
    ...adminEpics,
    ...assignmentEpics,
    ...questionEpics,
    ...websocketEpics
);
