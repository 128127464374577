/** @format */

import gql from "graphql-tag";

import { graphql_types, quiz } from "./types_and_fragments";

// add in created for me & auto quiz and untaken
// only show autoquizzes that are created for me
// quiz is whether my autoquiz has been taken; never show another auto quiz

// have a flag that says whether another autoquiz is to be created when this one is finished; will need a json field
//      on the quiz record with the required data
// TODO - look into sharing, too

export const RETRIEVE_RELEVANT_QUIZZES = gql(`
query filteredQuizzes(
    $quizzesPerPageRequest: Int!
    $cursor: Cursor
    $vRoleId: Int
    $vUserId: Int
    $vAutoCreated: Boolean
    $vCreatedById: Int
    $vMaxDifficulty: Float
    $vRequiredTags: [Int!]
    $vPartialQuizName: String
    $vOverdueBy: BigInt
    $vRequiredCes: [Int!]
    $vHideAutoQuizzesIfCompleted: Boolean
    $vMaxAsofdate: BigInt
    $vMinAsofdate: BigInt
    $vMinDifficulty: Float
    $vMaxQuestions: Int
    $vMinQuestions: Int
    ) {
    retrieveRelevantQuizzes(
        first: $quizzesPerPageRequest
        after: $cursor
        vRoleId: $vRoleId
        vUserId: $vUserId
        vAutoCreated: $vAutoCreated
        vCreatedById: $vCreatedById
        vMaxDifficulty: $vMaxDifficulty
        vRequiredTags: $vRequiredTags
        vPartialQuizName: $vPartialQuizName
        vOverdueBy: $vOverdueBy
        vRequiredCes: $vRequiredCes
        vHideAutoQuizzesIfCompleted: $vHideAutoQuizzesIfCompleted
        vMaxAsofdate: $vMaxAsofdate
        vMinAsofdate: $vMinAsofdate
        vMinDifficulty: $vMinDifficulty
        vMaxQuestions: $vMaxQuestions
        vMinQuestions: $vMinQuestions    
        ) {
        nodes {
            ...extendedQuizWithAutoFields  
        }
        pageInfo {
            endCursor
            hasNextPage
        }
    }
}
${quiz.extendedQuizWithAutoFields}
${quiz.extendedQuizFields}
${quiz.basicQuizFields}
`);

export interface IQuizzesData {
    retrieveRelevantQuizzes: {
        nodes: quiz.IQuizExtendedWithAuto[];
        pageInfo: graphql_types.IPageForwardInfo;
    };
}

export interface IQuizzesVariables {
    quizzesPerPageRequest: number;
    cursor: string | null;
    vRoleId?: number;
    vUserId?: number;
    vAutoCreated?: boolean;
    vCreatedById?: number;
    vMaxDifficulty?: number;
    vRequiredTags?: number[];
    vPartialQuizName?: string;
    vOverdueBy?: string; // BigInt
    vRequiredCes?: number[];
    vHideAutoQuizzesIfCompleted?: boolean;
    vMaxAsofdate?: string; // BigInt
    vMinAsofdate?: string; // BigInt
    vMinDifficulty?: number;
    vMaxQuestions?: number;
    vMinQuestions?: number;
}
