import { Enums } from "../../Resources";

export interface screenSizeInterface {
    mobile: boolean;
    tablet: boolean;
    desktop: boolean;
    largeDesktop: boolean;
    minMobile: boolean;
    minTablet: boolean;
    minDesktop: boolean;
    minLargeDesktop: boolean;
    maxMobile: boolean;
    maxTablet: boolean;
    maxDesktop: boolean;
    maxLargeDesktop: boolean;
}

const getDefaultSizeObject = () => {
    return {
        mobile: false,
        tablet: false,
        desktop: false,
        largeDesktop: false,
        minMobile: false,
        minTablet: false,
        minDesktop: false,
        minLargeDesktop: false,
        maxMobile: false,
        maxTablet: false,
        maxDesktop: false,
        maxLargeDesktop: false,
    };
};

const getDefaultDialogState = () => {
    return {
        isOpen: false,
        title: null,
        ContentComponent: null,
        buttonsType: Enums.DialogButtonTypes.CloseOnly,
        onCancel: () => {},
        onConfirm: () => {},
    };
};

export { getDefaultSizeObject, getDefaultDialogState };
