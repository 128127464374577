/** @format */

import gql from "graphql-tag";

export const STOP_QUIZZES = gql`
    mutation sq($input: StopQuizzesInput!) {
        stopQuizzes(input: $input) {
            clientMutationId
        }
    }
`;

export interface IVariables {
    input: {
        vRoleId: number;
        vQuizId: number;
    };
}
