/** @format */

import gql from "graphql-tag";

export const RESPOND_TO_INVITATION = gql(`
    mutation rti($input: RespondToInvitationInput!) {
        respondToInvitation(input: $input) {
            clientMutationId
        }
    }
`);

export interface IVariables {
    input: {
        vInvitationUuid: string;
        vAccept?: boolean;
        vReopen?: boolean;
    };
}
