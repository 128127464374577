/** @format */

import { createAction } from "./action_helpers";
import { ActionsUnion, ISaveAuthProps } from "./reducer_types";

export enum ActionTypes {
    SAVE_AUTH_PROPS = "SAVE_AUTH_PROPS",
}

export const Actions = {
    saveAuthProps: (payload: ISaveAuthProps) =>
        createAction(ActionTypes.SAVE_AUTH_PROPS, payload),
};

export type ActionsType = ActionsUnion<typeof Actions>;
