import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { of, Subject, interval } from "rxjs";
import {
    catchError,
    map,
    mapTo,
    mergeMap,
    switchMap,
    takeUntil,
    startWith,
    delay,
} from "rxjs/operators";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";

import { ActionTypes, AppActions } from "../app_actions";
import { IStore, IWsConnect, IWsSend, IWsDisconnect } from "../reducer_types";
import { HistoryWrapper } from "src/app/Configuration/BrowserHistory";

let webSocketSubject: WebSocketSubject<{}>;
let onOpenSubject = new Subject<Event>();
let onCloseSubject = new Subject<CloseEvent>();

// Create the websocket subject
const connectSocket = (websocketUrl, userUuid) => {
    onOpenSubject = new Subject();
    onCloseSubject = new Subject();
    webSocketSubject = webSocket({
        url: `${websocketUrl}/?userUuid=${userUuid}`,
        openObserver: onOpenSubject,
        closeObserver: onCloseSubject,
    });
    return webSocketSubject;
};

const connectedEpic = (action$, state$: StateObservable<IStore>) =>
    action$.pipe(
        ofType(ActionTypes.WEBSOCKET_TRY_CONNECT),
        switchMap(() =>
            onOpenSubject.pipe(
                map(() =>
                    onCloseSubject.pipe(
                        mergeMap(() => {
                            if (state$.value.app.maintainWs) {
                                return of(
                                    AppActions.wsConnect({
                                        userUuid: state$.value.app.wsUuid,
                                    })
                                );
                            }
                            return of(AppActions.wsDisconnected({}));
                        })
                    )
                )
            )
        ),
        mergeMap(() => of(AppActions.wsConnected({})))
    );

interface IWsDisconnectAction {
    type: string;
    payload: IWsDisconnect;
}

const disconnectedEpic = (
    action$: ActionsObservable<IWsDisconnectAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(
            ActionTypes.WEBSOCKET_TRY_DISCONNECT,
            ActionTypes.DO_NOT_MAINTAIN_WS
        ),
        mergeMap((action) => {
            if (action.payload.retry) {
                of(
                    AppActions.wsConnect({ userUuid: state$.value.app.wsUuid })
                ).pipe(delay(5000), startWith(AppActions.wsDisconnected));
            }
            if (onCloseSubject) {
                onCloseSubject.complete();
            }
            if (webSocketSubject) {
                webSocketSubject.complete();
            }
            return of(AppActions.wsDisconnected({}));
        })
    );

interface IConnectWsAction {
    type: string;
    payload: IWsConnect;
}

const epicWs = (
    action$: ActionsObservable<IConnectWsAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.WEBSOCKET_TRY_CONNECT),
        switchMap((action) =>
            connectSocket(
                process.env.REACT_APP_WSS_APIG_ENDPOINT,
                action.payload.userUuid
            ).pipe(
                // map((data) => AppActions.wsMessageReceived(data)),
                map((data: { [key: string]: any }) => {
                    let receivedAction: any = AppActions.epicCompleted(data);
                    switch (data.notificationType) {
                        case "pong":
                            receivedAction = AppActions.wsMessageReceived(data);
                            break;
                        case "echo":
                            break;
                        case "setupComplete":
                            // TODO - write a new action for dealing with this
                            // will need to "login" with the email address and wait for a code to be entered
                            if (
                                data.forwardedData &&
                                data.forwardedData.email
                            ) {
                                HistoryWrapper.history.push(
                                    `/?email=${encodeURIComponent(
                                        data.forwardedData.email
                                    )}&signInAutomatically=true`
                                );
                            }
                            break;
                        case "emailConfirmed":
                            // TODO - write new action for this; might need to requery whatever is related to the email verification request
                            break;
                        default:
                        //
                    }
                    return receivedAction;
                }),
                catchError((e) => {
                    console.log(e);
                    const nextActions = [
                        of({
                            type: ActionTypes.EPIC_ERROR,
                            payload: { errorData: e },
                        }),
                        of(AppActions.wsDisconnect({})),
                    ];
                    // return of(AppActions.wsDisconnect({}));
                    return nextActions;
                })
            )
        )
    );

interface IWsSendAction {
    type: string;
    payload: IWsSend;
}

const wsSendEpic = (
    action$: ActionsObservable<IWsSendAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.WEBSOCKET_MESSAGE_SEND),
        mergeMap((action) => {
            if (!webSocketSubject) {
                return of({
                    type: ActionTypes.WEBSOCKET_ERROR,
                    payload: {
                        message: `Attempted to send message while no connection was open.`,
                    },
                });
            }
            webSocketSubject.next(action.payload.message);
            return of(
                AppActions.wsMessageSent({ message: action.payload.message })
            );
        })
    );

interface IActionWithoutPayload {
    type: string;
}

const timeDelay = 1000 * 60 * 8; // 8 minutes; Api Gateway closes connections after 8 minutes of no response
const startToPing = (
    action$: ActionsObservable<IActionWithoutPayload>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.WEBSOCKET_CONNECTED),
        switchMap(() =>
            interval(timeDelay).pipe(
                takeUntil(
                    action$.ofType(
                        ActionTypes.WEBSOCKET_ERROR,
                        ActionTypes.WEBSOCKET_DISCONNECTED,
                        ActionTypes.WEBSOCKET_TRY_DISCONNECT,
                        ActionTypes.WEBSOCKET_STOP_PING,
                        ActionTypes.DO_NOT_MAINTAIN_WS
                    )
                ),
                mapTo({
                    type: ActionTypes.WEBSOCKET_MESSAGE_SEND,
                    payload: {
                        message: { action: "wping" },
                    },
                })
            )
        )
    );

const defaultExports = [
    epicWs,
    wsSendEpic,
    startToPing,
    connectedEpic,
    disconnectedEpic,
];

export default defaultExports;
