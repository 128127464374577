/** @format */

export const textLanguageFields = `
fragment textLanguageFields on TextLanguage {
    nodeId
    id
    languageCode
    languageName
    pgLang
}
`;

export interface ITextLanguage {
    nodeId: string;
    id: number;
    languageCode: string;
    languageName: string;
    pgLang: "danish" | "dutch" | "english" | "finnish" | "french" | "german" | "hungarian" | "italian" | "norwegian" | "portuguese" | "romanian" | "russian" | "simple" | "spanish" | "swedish" | "turkish" | undefined;
}
