/** @format */

import { IQuizFullQuestion } from "./quizQuestion";
import { ISpecificSubject } from "./specificSubject";
import { ISubjectArea } from "./subjectArea";

export interface IQuizBasic {
    nodeId: string;
    id: number;
    createdById: number;
    setBy: {
        publicName: string;
    };
    createdForId: number;
    ctUserRoleByCreatedForId: {
        id: number;
        publicName: string;
        classGroupingByClassGroupingId: {
            className;
        };
    };
    quizName: string;
    quizSubtitle: string;
    asofdate: string;
    dueAsofdate: string;
    averageDifficulty: number;
    difficultyDistribution: string; // JSONB
    automaticallyCreated: boolean;
    isNotes: boolean;
    isUnfinished: boolean;
    numQuestions: number;
    aclWrite: string[];
    ownerUuids: string[];
    selfAssessed: boolean;
    hideUntilTimeBeforeDue: number; // bigint
    dynamicLink: string;
}

// export interface IQuizBasicWithQuestions extends IQuizBasic {
//     quizQuestionsByQuizId: {
//         nodes: IQuizFullQuestion[];
//     };
// }

export interface IQuizExtended extends IQuizBasic {
    subjData: {
        ss: {
            id: number;
            nodeId: string;
            specificSubjectName: string;
            sa: {
                id: number;
                nodeId: string;
                areaName: string;
            };
        };
    };
}

// export interface IQuizExtendedWithQuestions extends IQuizExtended {
//     quizQuestionsByQuizId: {
//         nodes: IQuizFullQuestion[];
//     };
// }
export interface IQuizExtendedWithAuto extends IQuizExtended {
    autoQuizVariableSetByAutoQuizVariableSetId: {
        id: number;
        nodeId: string;
        maxavg: number;
        maxDifficulty: number;
        minDifficulty: number;
        maxScoreFilter: number;
        includeFacts: boolean;
        includeConcepts: boolean;
        includeComputations: boolean;
        earliestResults: string;
        minTimesMaxScoreToSkip: number;
        numQuestions: number;
        maxTimesToTake: number;
        creationCount: number;
        newQuizName: string;
        newQuizSubtitle: string;
        permitReversing: boolean;
        autoQuizSequenceId: string;
        autoQuizSequenceOrder: number;
        additionalData: any; // jsonb
        leaveOrderUnchanged: boolean;
        includeByTaglist: number[];
        includeByCurriculumIds: number[];
        limitBySourceContentCollectionIds: number[];
        createNextOnTestEvent: boolean;
        selfAssessed: boolean;
        hideUntilTimeBeforeDue: number; // bigint
    };
}

// export interface IQuizExtendedWithQuestionsAndAuto extends IQuizExtendedWithAuto {
//     quizQuestionsByQuizId: {
//         nodes: IQuizFullQuestion[];
//     };
// }

export interface IQuiz extends IQuizBasic {
    specificSubjectByPrinSpecificSubjectId: ISpecificSubject;
    subjectAreaBySubjectAreaId: ISubjectArea;
    autoQuizVariableSetByAutoQuizVariableSetId?: {
        id: number;
        nodeId: string;
        maxavg: number;
        maxDifficulty: number;
        minDifficulty: number;
        maxScoreFilter: number;
        includeFacts: boolean;
        includeConcepts: boolean;
        includeComputations: boolean;
        earliestResults: string;
        minTimesMaxScoreToSkip: number;
        selfAssessed: boolean;
        hideUntilTimeBeforeDue: number; // bigint
    };
}

export interface IQuizAllData extends IQuiz {
    quizQuestionsByQuizId: {
        nodes: IQuizFullQuestion[];
    };
}

export const basicQuizFields = `
fragment basicQuizFields on Quiz {
    nodeId
    id
    createdById
    setBy: ctUserRoleByCreatedById {
        publicName
    }
    createdForId
    ctUserRoleByCreatedForId {
        id
        publicName
        classGroupingByClassGroupingId {
            className
        }
    }
    quizName
    quizSubtitle
    asofdate
    dueAsofdate
    averageDifficulty
    difficultyDistribution
    automaticallyCreated
    isNotes
    isUnfinished
    numQuestions
    aclWrite
    ownerUuids
    selfAssessed
    hideUntilTimeBeforeDue
    dynamicLink
}
`;

// uses basicQuizFields
export const extendedQuizFields = `
fragment extendedQuizFields on Quiz {
    ...basicQuizFields
    subjData: ctUserRoleByCreatedForId {
        ss: specificSubjectBySpecificSubjectId {
            id
            nodeId
            specificSubjectName
            sa: subjectAreaBySubjectAreaId {
                id
                nodeId
                areaName
            }
        }
    }
}
`;

// uses basicQuizFields, extendedQuizFields
export const extendedQuizWithAutoFields = `
fragment extendedQuizWithAutoFields on Quiz {
    ...extendedQuizFields
    autoQuizVariableSetByAutoQuizVariableSetId {
        id
        nodeId
        maxavg
        maxDifficulty
        minDifficulty
        maxScoreFilter
        includeFacts
        includeConcepts
        includeComputations
        earliestResults
        minTimesMaxScoreToSkip
        numQuestions
        maxTimesToTake
        creationCount
        newQuizName
        newQuizSubtitle
        permitReversing
        autoQuizSequenceId
        autoQuizSequenceOrder
        additionalData
        leaveOrderUnchanged
        includeByTaglist
        includeByCurriculumIds
        limitBySourceContentCollectionIds
        createNextOnTestEvent
        selfAssessed
        hideUntilTimeBeforeDue
    }
}
`;
