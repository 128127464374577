/** @format */

import * as curriculumElement from "./curriculumElement";
import * as subjectArea from "./subjectArea";

// requires
// ${subjectArea.subjectAreaBasicFields}
// ${textLanguage.textLanguageFields}
export const specificSubjectBasicFields = `
fragment specificSubjectBasicFields on SpecificSubject {
    nodeId
    id
    specificSubjectName
    gradeLevel
    startingCe
    subjectAreaBySubjectAreaId {
        ...subjectAreaBasicFields
    }
}
`;

// requires
// ${subjectArea.subjectAreaBasicFields}
// ${textLanguage.textLanguageFields}
// ${curriculumElement.ceFullFields}
export const specificSubjectFullFields = `
fragment specificSubjectFullFields on SpecificSubject {
    nodeId
    id
    fullWriteList
    specificSubjectName
    gradeLevel
    startingCe
    subjectAreaBySubjectAreaId {
        ...subjectAreaBasicFields
    }
    currElements: curriculumElementsBySpecificSubjectId(orderBy: DISPLAY_ORDER_ASC) {
        nodes {
            ...ceFullFields
        }
    }    
}
`;

export interface ISpecificSubjectBasic {
    nodeId: string;
    id: number;
    fullWriteList: string[];
    specificSubjectName: string;
    gradeLevel: string;
    startingCe: number;
    subjectAreaBySubjectAreaId: subjectArea.ISubjectArea;
}

export interface ISpecificSubject extends ISpecificSubjectBasic {
    currElements?: {
        nodes: curriculumElement.ICeFull[];
    };
}
