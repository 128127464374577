/** @format */

import gql from "graphql-tag";

import { subjectArea } from "./types_and_fragments";

export const GET_ONE_SUBJECT_AREA = gql(`
    query oneSubjectArea($specificSubjectId: Int!) {
        specificSubjectById(id: $specificSubjectId) {
            nodeId
            id
            specificSubjectName
            gradeLevel
        }
        minContentCollectionDate(vSpecificSubjectId: $specificSubjectId)
        maxContentCollectionDate(vSpecificSubjectId: $specificSubjectId)
        minContentCollectionQuestions(vSpecificSubjectId: $specificSubjectId)
        maxContentCollectionQuestions(vSpecificSubjectId: $specificSubjectId)        
    }
`);

export interface IOneSubjAreaVars {
    specificSubjectId: number;
}

export interface IOneSubjAreaData {
    subjectArea: subjectArea.ISubjectArea;
    minQuizDate: string;
    maxQuizDate: string;
    minQuizQuestions: number;
    maxQuizQuestions: number;
}
