import generalActions from "./GeneralActions";
import accountActions from "./AccountActions";

export { accountActions };

export function linkActions(dispatch, actions) {
    let result = {
        ...generalActions(dispatch),
    };
    if (actions instanceof Array) {
        actions.forEach((element) => {
            result = {
                ...result,
                ...element(dispatch),
            };
        });
    }
    return result;
}
