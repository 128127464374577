/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Redirect, useLocation } from "react-router-dom";

import Amplify from "aws-amplify";

import { Actions, AppActions } from "../redux/app_actions";
import asyncComponent from "../app/AsyncComponent";
import { clearToasts } from "src/app/AppSupport/Utils";
import { IStore } from "src/redux/reducer_types";

const AsyncApp = asyncComponent(() => import("./App"));
const AsyncSignInSignUpParent = asyncComponent(() =>
    import("./admin/SignInSignUpParent")
);
const AsyncDevSignIn = asyncComponent(() => import("./admin/DevSignIn"));

// common locale data; N.B. not used directly by this file; possibly should be moved up to index.tsx
// these files don't have much useful info; keeping one just in case
// require("intl/locale-data/jsonp/en.js");
require("intl/locale-data/jsonp/en-GB.js");
// require("intl/locale-data/jsonp/en-US.js");
// require("intl/locale-data/jsonp/de.js");

// const storageBucket = process.env.REACT_APP_PROD_STORAGE;

const amplifyConfig = {
    Auth: {
        authenticationFlowType:
            process.env.REACT_APP_REVIEW_APP === "true"
                ? "USER_SRP_AUTH"
                : "CUSTOM_AUTH",
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        mandatorySignIn: false,
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_APP_ID,
    },
    API: {
        endpoints: [
            {
                name: "valearnmain",
                endpoint: process.env.REACT_APP_API_URL,
                region: "eu-west-1",
            },
        ],
    },
    Storage: {
        AWSS3: {
            // bucket: storageBucket,
            bucket: "valearn-image-uploads-production",
            region: process.env.REACT_APP_REGION,
        },
    },
};
Amplify.configure(amplifyConfig);

// Optionally add Debug Logging N.B. this overwrites any log levels on other loggers; best to leave it out
// Amplify.Logger.LOG_LEVEL = "INFO";

const AppWithAuth: React.FC = () => {
    let dispatch = useDispatch();
    const location = useLocation();
    const { lang, urlEmail } = useParams();

    const [passwordless] = useState(
        process.env.REACT_APP_REVIEW_APP === "true" ? false : true
    );

    const language = useSelector((state: IStore) => state.app.language);
    const authStatus = useSelector((state: IStore) => state.app.authStatus);

    const authStatusRef = useRef() as any;

    // const waitForWidgetInitialised = async () =>
    //     new Promise((resolve) => {
    //         // @ts-ignore
    //         window.zE(() => {
    //             // @ts-ignore
    //             $zopim(() => {
    //                 // @ts-ignore
    //                 $zopim.livechat.hideAll();
    //                 resolve(true);
    //             });
    //         });
    //     });
    // // // for zendesk
    // useEffect(() => {
    //     if (window.innerWidth > 320) {
    //         // MINTABLETWIDTH) {
    //         const script = document.createElement("script");
    //         script.src =
    //             "https://static.zdassets.com/ekr/snippet.js?key=311425d6-fd3e-42d8-b482-2f025c4e479d";
    //         script.id = "ze-snippet";
    //         script.async = true;
    //         document.body.appendChild(script);
    //         waitForWidgetInitialised();
    //     }
    // }, []);

    // for usersnap
    // useEffect(() => {
    //     async function handleUsersnap() {
    //         // console.log(
    //         //     `initialising userSnap with email of ${dbUser && dbUser.email}`
    //         // );
    //         // // @ts-ignore
    //         // if (window.Usersnap) {
    //         //     // @ts-ignore
    //         //     await window.Usersnap.destroy();
    //         // }
    //         const script = document.createElement("script");
    //         // @ts-ignore
    //         window.onUsersnapCXLoad = function (api) {
    //             api.init({
    //                 email: dbUser && dbUser.email,
    //                 custom: {
    //                     cognitoSub: dbUser && dbUser.cognitoSub,
    //                     language,
    //                 },
    //             });
    //             // @ts-ignore
    //             window.Usersnap = api;
    //         };
    //         // @ts-ignore
    //         script.defer = 1;
    //         script.src =
    //             "https://widget.usersnap.com/global/load/28fb6dec-28e6-4444-93b9-c3e042a36c68?onload=onUsersnapCXLoad";
    //         document.getElementsByTagName("head")[0].appendChild(script);
    //     }
    //     handleUsersnap();
    // }, []); // dbUser  (doesn't work to re-initialise.  waiting for usersnap help)

    useEffect(() => {
        if (lang) {
            dispatch(Actions.requestNewLanguage({ language: lang }));
        }
        // setInitialLanguage();
        // N.B. to run only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang]);

    useEffect(() => {
        if (urlEmail) {
            dispatch(
                Actions.saveSignUpParams({
                    urlEmail: decodeURIComponent(urlEmail),
                })
            );
        }
    }, [urlEmail]);

    useEffect(() => {
        if (passwordless) {
            Amplify.configure({
                Auth: {
                    authenticationFlowType: "CUSTOM_AUTH",
                },
            });
        } else {
            Amplify.configure({
                Auth: {
                    authenticationFlowType: "USER_SRP_AUTH",
                },
            });
        }
    }, [passwordless]);

    useEffect(() => {
        if (authStatus !== authStatusRef.current) {
            if (
                authStatus === "signedIn" &&
                authStatusRef.current === "signIn"
            ) {
                // remove any toasts leftover form sign in / sign up
                clearToasts();
                dispatch(AppActions.wsDoNotMaintain({}));
            }
            authStatusRef.current = authStatus;
        }
    }, [authStatus]);

    const render = () => {
        // console.log(
        //     `rendering AppWithAuth.  Have authStatus = "${authStatus}" and the ref is "${authStatusRef.current}"`
        // );
        // console.log(`rendering pathname is ${location.pathname}`);

        if (!passwordless) {
            // console.log("rendering AsyncDevSignIn");
            return <AsyncDevSignIn />;
        } else if (authStatus === "signIn") {
            // console.log("rendering signinsignupparent");
            return <AsyncSignInSignUpParent />;
        } else if (
            authStatus === "signedIn" &&
            location.pathname.indexOf("/site") !== -1
        ) {
            // once /site/:lang?/:urlEmail has been handled above, redirect to remove those params
            // console.log("rendering redirect");
            return <Redirect to="/" />;
        } else if (authStatus === "signedIn") {
            // console.log("rendering App");
            return (
                <AsyncApp
                    authStatus={authStatus}
                    initialAppLoad={authStatus !== authStatusRef.current}
                />
            );
        } else {
            console.log(`unhandled authstatus: ${authStatus}`);
            return <div></div>;
        }
    };

    if (language) {
        // the render function will remove /site/lang/urlEmail through a redirect above
        return render();
    } else {
        return <div>Loading language file...</div>;
    }
};

export default AppWithAuth;
