import React, { useEffect, useContext, useState } from "react";
import { InitialStates } from "../app/Constants";
import { screenSizeInterface } from "../app/Constants/InitialStates";

type ResponsiveContextProps = {
    screenSize?: screenSizeInterface;
};

const ResponsiveContext = React.createContext<Partial<ResponsiveContextProps>>(
    {}
);

export function useSensor() {
    const { screenSize } = useContext(ResponsiveContext);

    const [result, setResult] = useState(InitialStates.getDefaultSizeObject());

    useEffect(() => {
        if (screenSize) setResult(screenSize);
        // else setResult({}); // default value set in createContext above
    }, [screenSize]);

    return {
        screenSize: result,
    };
}

const ResponsiveHandler = (props) => {
    const detectDevice = () => {
        const result = InitialStates.getDefaultSizeObject();

        const width = window.innerWidth;
        if (width <= 767) {
            result.mobile = true;
        } else if (width >= 768 && width <= 991) {
            result.tablet = true;
        } else if (width >= 992 && width <= 1199) {
            result.desktop = true;
        } else {
            result.largeDesktop = true;
        }

        result.minMobile =
            result.mobile ||
            result.tablet ||
            result.desktop ||
            result.largeDesktop;
        result.minTablet =
            result.tablet || result.desktop || result.largeDesktop;
        result.minDesktop = result.desktop || result.largeDesktop;
        result.minLargeDesktop = result.largeDesktop;

        result.maxMobile = result.mobile;
        result.maxTablet = result.mobile || result.tablet;
        result.maxDesktop = result.mobile || result.tablet || result.desktop;
        result.maxLargeDesktop =
            result.mobile ||
            result.tablet ||
            result.desktop ||
            result.largeDesktop;

        return result;
    };

    const [screenSize, setScreenSize] = useState(detectDevice());

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleResize = () => {
        setScreenSize({ ...screenSize, ...detectDevice() });
    };

    return (
        <ResponsiveContext.Provider
            value={{
                screenSize,
            }}
        >
            {props.children}
        </ResponsiveContext.Provider>
    );
};

export default ResponsiveHandler;
