import { parsePhoneNumber as parsePhoneNumberFromString } from "libphonenumber-js/mobile";

const parseNumber = (number) => {
    try {
        const ph = parsePhoneNumberFromString(number);
        if (ph.isValid()) {
            return {
                fullMobileNumber: ph.number,
                mobileErrorMsg: undefined,
            };
        }
        return {
            fullMobileNumber: undefined,
            mobileErrorMsg: "simply_not_valid",
        };
    } catch (error) {
        return {
            fullMobileNumber: undefined,
            mobileErrorMsg: error.message,
        };
    }
};

export { parseNumber };
