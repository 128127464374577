/** @format */

export const ceBasicFields = `
fragment ceBasicFields on CurriculumElement {
    nodeId
    id
    elementText
    displayOrder
    chapter
    section
}
`;

export interface ICurriculumElement {
    nodeId: string;
    id: number;
    elementText: string;
    displayOrder: number;
    chapter: string;
    section: string;
}

export interface ICurriculumElementById {
    nodeId: string;
    id: number;
    curriculumElementId: number;
    curriculumElementByCurriculumElementId: {
        elementText: string;
    };
}

export const ceFullFields = `
fragment ceFullFields on CurriculumElement {
    nodeId
    id
    elementText
    displayOrder
    chapter
    section
    ceDesc
    publisher
    additionalInfo
    elementParentId
    lft
    rgt
    prereqs: curriculumElementEdgesByToCe {
        nodes {
            fromCe
            minPrerequisiteMasteryNeeded
            comment
        }
    }
}
`;

export interface ICeFull extends ICurriculumElement {
    ceDesc: string;
    publisher: string;
    additionalInfo: any; // JSONB
    elementParentId: number;
    lft: number;
    rgt: number;
    prereqs: {
        nodes: {
            fromCe: number;
            minPrerequisiteMasteryNeeded: number;
            comment: string;
        }[];
    };
}
