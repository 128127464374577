const defaultExport = {
    General: {
        SetCurrentLanguage: "REQUEST_NEW_LANGUAGE",
        SetDialogStatus: "general_set_dialog_status",
    },
    Account: {
        SetRegistrationDetails: "account_set_registration_details",
        SetLoginDetails: "account_set_login_details",
    },
};

export default defaultExport;
