import AWS from "aws-sdk";
import * as Sentry from "@sentry/browser";

import parentLogger from "src/app/AppSupport/Utils/loggerService";

const logger = parentLogger.child({
    module: "AWSUtils",
});

export const longUpload = async (key, object, config) => {
    const userEmailKey = `CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_WEB_APP_ID}.LastAuthUser`;
    const userEmail = localStorage.getItem(userEmailKey);
    const idTokenKey = `CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_WEB_APP_ID}.${userEmail}.idToken`;
    const token = localStorage.getItem(idTokenKey);
    if (!token) {
        throw new Error("no id token");
        // TODO - send to Sentry
    }

    const identityIdKey = `aws.cognito.identity-id.${process.env.REACT_APP_IDENTITY_POOL_ID}`;
    const identityId = localStorage.getItem(identityIdKey);
    if (!identityId) {
        Sentry.captureMessage("no identityId found in localStorage ");
        logger.error("No identityId found in localStorage");
        throw new Error("no identityId");
    }

    // const tokenKey = "cognito-idp." + process.env.REACT_APP_REGION +
    // ".amazonaws.com/" + process.env.REACT_APP_USER_POOL_ID;
    const tokenKey = `cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`;
    const logins = {};
    logins[tokenKey] = token;

    const openIdParams = {
        IdentityId: identityId,
        Logins: logins,
    };

    try {
        const cognitoidentity = new AWS.CognitoIdentity({
            region: process.env.REACT_APP_REGION,
        });
        const openIdToken = await cognitoidentity
            .getOpenIdToken(openIdParams)
            .promise();

        const stsParams = {
            DurationSeconds: 43200,
            // optional / intersection: Policy: "{\"Version\":\" etc.
            RoleArn:
                "arn:aws:iam::917249922596:role/football-20181219132837-authRole",
            RoleSessionName: userEmail, // not sure about this
            WebIdentityToken: openIdToken.Token,
        };

        try {
            const sts = new AWS.STS();
            const credentials = await sts
                // @ts-ignore -- copied from API; maybe types are wrong
                .assumeRoleWithWebIdentity(stsParams)
                .promise();

            const accessparams = {
                accessKeyId: credentials.Credentials!.AccessKeyId,
                httpOptions: { timeout: 0 },
                maxRetries: 20,
                secretAccessKey: credentials.Credentials!.SecretAccessKey,
                sessionToken: credentials.Credentials!.SessionToken,
            };
            const s3 = await new AWS.S3(accessparams);

            const storageBucket = process.env.REACT_APP_PROD_STORAGE;

            const finalKey = "protected/" + identityId + "/" + key;
            const s3UploadParams: any = {
                Body: object,
                Bucket: storageBucket,
                ContentType: config.contentType,
                Key: finalKey,
            };

            const upload = s3
                .upload(s3UploadParams)
                .on("httpUploadProgress", (progress) => {
                    if (config.progressCallback) {
                        if (typeof config.progressCallback === "function") {
                            config.progressCallback(progress);
                        } else {
                            Sentry.captureMessage(
                                "progressCallback should be a function, not a " +
                                    typeof config.progressCallback
                            );
                        }
                    }
                });
            const data = await upload.promise();
            return data;
        } catch (e) {
            Sentry.captureException(e);
        }
    } catch (e) {
        Sentry.captureException(e);
    }
};

// export const getRoleChoiceItems = (
//     activeRole: userRole.IUserRole,
//     chooseRole: (payload: IChooseRole) => void,
//     roles: userRole.IUserRole[],
//     chooseStudent: (payload: IChooseStudent) => void,
//     activeStudent: userRole.IStudentGroupMember,
// ) => {
//     const items = roles
//         .map((role) => {
//             return {
//                 key: role.id,
//                 name: role.roleDescription,
//                 onClick: chooseRole.bind(null, { roleId: role.id }),
//             };
//         });
//     const name = `Current: ${
//         activeRole ? activeRole.roleDescription || "Select role" : "Select role"
//     }`;

//     const students = activeRole.studentGroupMembers || [];
//     const studentItems = students.map((student) => {
//         return {
//             key: student.studentId,
//             name: student.studentName,
//             onClick: chooseStudent.bind(null, { studentId: student.studentId }),
//         };
//     });
//     const studentName = `Current: ${
//         activeStudent ? activeStudent.studentName : "No student selected"
//     }`;

//     const farItems = [
//         {
//             iconProps: {
//                 iconName: "PlayerSettings",
//             },
//             key: "roleselect",
//             name,
//             subMenuProps: {
//                 items,
//             },
//         },
//     ];
//     if (studentItems.length > 0) {
//         farItems.push(
//             {
//                 iconProps: {
//                     iconName: "PlayerSettings",
//                 },
//                 key: "studentselect",
//                 name: studentName,
//                 subMenuProps: {
//                     items: studentItems,
//                 },
//             },
//         );
//     }
//     return farItems;
// };
